<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <filtrable-table
                    id="ordersListTogenerate"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="orders"
                    :columns="columns"
                    :hoverableItems="true"
                    :storeToSort="'orders'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="multiActions"
                    :subTableMultiActions="subTableMultiActions"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    @changeSearch="changeSearch($event)"
                    @checkboxFilterUpdate="checkboxFilter($event)"
                    @dateFilterUpdate="setDateFilter($event)"
                    :itemActions="itemActions"
                    :subTableItemActions="subTableItemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="canAddNew()"
                    @pageChanged="setPage($event)"
                    @update="goToUpdateOne($event)"
                    @searched="debounceSearch"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @generateBillForMany="generateBill($event, true, false)"
                    @generateBill="generateBill($event)"
                    @generateProforma="generateBill($event, false, true)"
                    @generateProformaForMany="generateBill($event, true, true)"
                    @generateBillForManySales="
                        generateBillSales($event, true, false)
                    "
                    @generateBillSales="generateBillSales($event)"
                    @generateProformaSales="
                        generateBillSales($event, false, true)
                    "
                    @generateProformaForManySales="
                        generateBillSales($event, true, true)
                    "
                >
                    <!-- :hoverableItems="userStore.isB2C === false" -->
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useOrderStore } from '@/stores/orderStore'
import { useSaleStore } from '@/stores/saleStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'BillsToGenerateList',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            columns: [],
            confirmAction: null,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModaltext: 'confirm_modal_text',
            confirmModaltext2: 'confirm_modal_text',
            driverOptions: [],
            dateFilter: [],
            resetCheckedItems: false,
            // pickupTodayOnly: false,
            // deliveryTodayOnly: false,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 25,
            page: 1,
            filter: [],
            ordersInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Order.Client.Name'
                            : 'Reference',
                },
                // { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
        }
    },
    watch: {
        orders(oldVal, newVal) {
            if (
                this.page === 1 &&
                oldVal !== newVal &&
                (localStorage.getItem('popoverOpen') === null ||
                    localStorage.getItem('popoverOpen') === false)
            ) {
                this.refresh += 1
            }
        },
        // drivers() {
        //     if (this.drivers !== null) {
        //         // clearInterval(driversInterval)
        //         this.drivers.forEach((driver) => {
        //             const name = `${driver.firstname} ${driver.lastname}`
        //             const option = {
        //                 name,
        //                 value: +driver.id,
        //                 dbField: 'OrderStep.IdDriver',
        //             }
        //             this.driverOptions.push(option)
        //         })
        //     }
        // },
    },
    created() {
        window.scrollTo(0, 0)
        this.searchingCursor = true
        const filteredList = this.$cookies.get('filteredToGenerate')

        const b2cInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(b2cInterval)
                this.columns = [
                    {
                        name: 'client',
                        showRef: this.searchByName === false,
                        filtrable: true,
                        dbField:
                            this.searchByName === true
                                ? 'Order.Client.Name'
                                : 'Reference',
                        animated: true,
                        routeName: 'update_order',

                        isLink: checkAccess(
                            this.userStore,
                            actionsAccess.orders.update,
                        ),
                        searchIcon:
                            this.searchByName === true ? 'user' : 'hashtag',
                        type: 'search',
                        sortable: false,
                        hide: this.userStore.isB2C
                            ? this.userStore.isB2C
                            : this.$cookies.get('is_B2C') === 'true',
                        subInfo: true,
                        expandable: true,
                    },
                    {
                        name: 'conteneurRef',
                        filtrable: true,
                        type: 'search',
                        sortable: false,
                        dbField: 'OrderPackage.ReferenceExternal',
                        hide:
                            checkAccess(
                                this.userStore,
                                actionsAccess.orders.displayRefConteneur,
                            ) === false
                                
                        // selected: ,
                    },
                    {
                        name: 'amount_to_bill',
                        filtrable: false,
                        sortable: false,
                    },
                    {
                        name: 'distance_duration',
                        filtrable: false,
                        sortable: false,
                    },

                    {
                        name: 'pick_up',
                        filtrable: false,
                        notCentered: false,
                        sortable: false,
                        dbField: 'Order.DateBegin',
                        datePicker: true,
                        range: true,

                        withButton: false,
                        pickedDate: '',
                        // buttonValue: this.pickupTodayOnly,
                        canUpdate: false,
                        noStepInfo: true,
                    },
                    {
                        name: 'delivery',
                        filtrable: false,
                        notCentered: false,
                        withButton: false,
                        canUpdate: false,
                        range: true,

                        // buttonValue: this.deliveryTodayOnly,
                        dbField: 'Order.DateEnd',
                        datePicker: true,
                        pickedDate: '',
                        sortable: false,
                        noStepInfo: true,
                    },
                    {
                        name: 'additional_info',
                        filtrable: false,
                        sortable: false,
                        packages: true,
                        noStepInfo: true,
                    },
                ]

                if (this.userStore.isB2C === true) {
                    this.$router.push({ name: 'bills' })
                } else {
                    clearInterval(this.ordersInterval)

                    this.orders = null
                    this.count = null
                    this.totalPages = null
                    this.filter = []
                    this.filter.push({
                        dbField: 'PaymentStatus',
                        value: 'none',
                    })

                    if (filteredList) {
                        // this.search.push(this.$cookies.get('query'))
                        this.search = filteredList.query
                        // this.searchQuery = filteredList.query[0]
                        //     ? filteredList.query[0].term
                        //     : ''
                        this.sortDirection = filteredList.sortDirection
                        this.filter = filteredList.filter
                        this.sortField = filteredList.sortField
                        this.perPage = filteredList.perPage
                        this.page = filteredList.page
                        // this.pickupTodayOnly = filteredList.pickupTodayOnly
                        // this.deliveryTodayOnly = filteredList.deliveryTodayOnly

                        this.dateFilter = filteredList.dateFilter
                        this.searchByName = filteredList.searchByName
                    }
                    // else {
                    //     this.filter.push({
                    //         dbField: 'PaymentStatus',
                    //         value: 'none',
                    //     })
                    // }
                    // if (this.userStore.userUniverses.length === 1) {
                    // this.selectedUniverses = structuredClone(
                    //     this.userStore.userUniverses
                    // )
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            const universeFilter = {
                                dbField: 'IdUniverse',
                                value:
                                    this.selectedUniverses.length > 0
                                        ? this.selectedUniverses
                                        : this.universesOptions,
                            }
                            if (
                                !this.filter.find(
                                    (f) => f.dbField === 'IdUniverse',
                                )
                            ) {
                                this.filter.push(universeFilter)
                            }
                            // }
                            const payload = {
                                query: [],
                                filter: [],
                                perPage: 10000,
                                page: 1,
                                sortField: 'Name',
                                sortDirection: 'ASC',
                            }
                            this.searchContainers(payload)
                            this.setResults()
                            this.eventBus.on(
                                'trigger-universe-search',
                                (param) => {
                                    this.searchCustomUniverses(param)
                                },
                            )
                            this.eventBus.on(
                                'remove-from-selected-universe',
                                (index) => {
                                    this.removeFromSelectedUniverses(index)
                                },
                            )
                        }
                    }, 10)
                }
            }
        }, 10)
    },

    unmounted() {
        clearInterval(this.ordersInterval)
        this.eventBus.all.clear()

        this.orders = null
        this.count = null
        this.totalPages = null
    },

    computed: {
        billingsActionsAccess() {
            return actionsAccess.billings
        },

        statusOptions() {
            const options = [
                {
                    name: 'delivery_status.in_progress',
                    value: 'inprogress',
                    dbField: 'Status',
                    icon: 'route',
                },
                {
                    name: 'delivery_status.finished',
                    value: 'finished',
                    icon: 'flag-checkered',
                    dbField: 'Status',
                },
                {
                    name: 'delivery_new',
                    value: 'new',
                    dbField: 'Status',
                    icon: 'hourglass-half',
                },
            ]

            return options
        },

        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                // &&
                // (this.filter.find((f) => f.dbField === 'Order.Client.Name') ||
                //     this.filter.find((f) => f.dbField === 'Reference'))
                actions.push({
                    name: 'generate_bill_for_many_sales',
                    action: 'generateBillForMany',
                    icon: 'file-invoice-dollar',
                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma_for_many',
                    action: 'generateProformaForMany',
                    icon: 'file-lines',
                    hide: false,
                })
            }

            return actions
        },
        subTableMultiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                actions.push({
                    name: 'generate_bill_for_many_sales',
                    action: 'generateBillForManySales',
                    icon: 'file-invoice-dollar',
                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma_for_many_sales',
                    action: 'generateProformaForManySales',
                    icon: 'file-lines',
                    hide: false,
                })
            }

            return actions
        },
        itemActions() {
            const actions = []

            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                actions.push({
                    name: 'generate_bill',
                    action: 'generateBill',
                    icon: 'file-invoice-dollar',

                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma',
                    action: 'generateProforma',
                    icon: 'file-lines',

                    hide: false,
                })
            }
            if (checkAccess(this.userStore, actionsAccess.orders.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                    hide: false,
                })
            }

            return actions
        },
        subTableItemActions() {
            const actions = []

            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                actions.push({
                    name: 'generate_bill',
                    action: 'generateBillSales',
                    icon: 'file-invoice-dollar',

                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma',
                    action: 'generateProformaSales',
                    icon: 'file-lines',

                    hide: false,
                })
            }

            return actions
        },
        ...mapWritableState(useOrderStore, {
            orders: 'allNotInvoiced',
            count: 'countNotInvoiced',
            fetchError: 'fetchError',
            totalPages: 'totalPagesNotInvoiced',
            loading: 'loading',
        }),
        ...mapStores(useUserStore, useOrderStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        canAddNew() {
            return false
        },
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
            'fetchOne',
        ]),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useOrderStore, [
            'searchNotInvoicedOrders',
            'generateInvoice',
        ]),
        ...mapActions(useSaleStore, ['generateSaleDetailsInvoice']),
        generateBill(orders, many = false, proforma = false) {
            const ocs = []
            if (many === false) {
                ocs.push({ Id: orders })
            } else {
                if (!Array.isArray(orders)) {
                    orders = [orders]
                }
                orders.forEach((oc) => {
                    ocs.push({ Id: oc })
                })
            }
            this.generateInvoice({ OrderClients: ocs, Proforma: proforma })
                .then((res) => {
                    this.$toast.success(this.$t('invoice_generation_launched'))
                    if (proforma === true) {
                        this.axios
                            .get(`/api/v1/invoice/${res.data.Id}/pdf`, {
                                responseType: 'blob',
                                headers: {
                                    Authorization: `Bearer ${this.$cookies.get(
                                        'user_session',
                                    )}`,
                                },
                                signal: this.$controller.signal,
                            })
                            .then((res) => {
                                const url = URL.createObjectURL(res.data)
                                const link = document.createElement('a')
                                link.href = url
                                link.target = '_external'
                                document.body.appendChild(link)
                                link.click()
                                document.body.removeChild(link)
                                // URL.revokeObjectURL(url)
                                // link.setAttribute('download', file)

                                // document.body.appendChild(link)
                                // link.click()
                                // document.body.removeChild(link)
                                // URL.revokeObjectURL(url)
                            })
                    }
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('invoice_generation_failed'))
                    if (err.response.data.message) {
                        this.$toast.warning(this.$t(err.response.data.message))
                    }
                })
        },
        goToUpdateOne(id) {
            const unpaid = this.orders.find((order) => order.id === id)
            clearInterval(this.ordersInterval)
            this.$router.push({
                name: 'update_order',
                params: { id: unpaid.orderId },
            })
        },
        generateBillSales(sales, many = false, proforma = false) {
            const salesPayload = []
            if (many === false) {
                salesPayload.push({ Id: sales })
            } else {
                if (!Array.isArray(sales)) {
                    sales = [sales]
                }
                sales.forEach((sale) => {
                    salesPayload.push({ Id: sale })
                })
            }
            this.generateSaleDetailsInvoice({
                SaleDetails: salesPayload,
                Proforma: proforma,
            })
                .then((res) => {
                    this.$toast.success(this.$t('invoice_generation_launched'))
                    if (proforma === true) {
                        this.axios
                            .get(`/api/v1/invoice/${res.data.Id}/pdf`, {
                                responseType: 'blob',
                                headers: {
                                    Authorization: `Bearer ${this.$cookies.get(
                                        'user_session',
                                    )}`,
                                },
                                signal: this.$controller.signal,
                            })
                            .then((res) => {
                                const url = URL.createObjectURL(res.data)
                                const link = document.createElement('a')
                                link.href = url
                                link.target = '_external'
                                document.body.appendChild(link)
                                link.click()
                                document.body.removeChild(link)
                                // URL.revokeObjectURL(url)
                                // link.setAttribute('download', file)

                                // document.body.appendChild(link)
                                // link.click()
                                // document.body.removeChild(link)
                                // URL.revokeObjectURL(url)
                            })
                    }
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('invoice_generation_failed'))
                    if (err.response.data.message) {
                        this.$toast.warning(this.$t(err.response.data.message))
                    }
                })
        },
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },

        changeSearch(columnIndex = null) {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Order.Client.Name') {
                    this.search[index].dbField = 'Reference'
                } else if (filter.dbField === 'Reference') {
                    this.search[index].dbField = 'Order.Client.Name'
                }
            })
            if (columnIndex !== null) {
                this.columns[columnIndex] = {
                    name:
                        this.searchByName === false
                            ? 'client_n_reference'
                            : 'client',
                    showRef: this.searchByName === false,
                    filtrable: true,
                    dbField:
                        this.searchByName === true
                            ? 'Order.Client.Name'
                            : 'Reference',
                    isLink: checkAccess(
                        this.userStore,
                        actionsAccess.orders.update,
                    ),
                    routeName: 'update_order',
                    searchIcon: this.searchByName === true ? 'user' : 'hashtag',
                    type: 'search',
                    animated: true,
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: this.searchByName === true,
                }
                this.refresh += 1
            }
            this.setResults()
        },

        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },

        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmModal = false
        },

        // checkboxFilter(dbField) {
        //     this.searchingCursor = true
        //     clearInterval(this.ordersInterval)
        //     const filtToRemoveIndex = this.dateFilter.findIndex(
        //         (filt) => filt.dbField === dbField
        //     )
        //     if (dbField === 'Order.DateBegin') {
        //         this.pickupTodayOnly = !this.pickupTodayOnly
        //         if (this.pickupTodayOnly === true) {
        //             if (filtToRemoveIndex !== -1) {
        //                 this.dateFilter.splice(filtToRemoveIndex, 1)
        //             }
        //         }
        //     }
        //     if (dbField === 'Order.DateEnd') {
        //         this.deliveryTodayOnly = !this.deliveryTodayOnly
        //         if (this.deliveryTodayOnly === true) {
        //             if (filtToRemoveIndex !== -1) {
        //                 this.dateFilter.splice(filtToRemoveIndex, 1)
        //             }
        //         }
        //     }
        //     this.setResults()
        // },
        setDateFilter(dFilter) {
            this.searchingCursor = true

            // if (dFilter.dbField === 'Order.DateBegin') {
            //     this.pickupTodayOnly = false
            // }
            // if (dFilter.dbField === 'Order.DateEnd') {
            //     this.deliveryTodayOnly = false
            // }
            const filterExistsIndex = this.dateFilter.findIndex(
                (df) => df.dbField === dFilter.dbField,
            )
            if (filterExistsIndex !== -1) {
                this.dateFilter[filterExistsIndex] = dFilter
            } else {
                this.dateFilter.slice(filterExistsIndex, 1)

                this.dateFilter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }

            this.setResults()
        },
        setPage(isNext) {
            clearInterval(this.ordersInterval)
            this.$cookies.remove('filteredToGenerate')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('filteredToGenerate')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            search.toInvoice = true
            search.searchByName = this.searchByName

            // const query = search.query

            this.$cookies.set('filteredToGenerate', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId = ''

            clearInterval(this.ordersInterval)
            this.searchNotInvoicedOrders(search).then(() => {
                this.searchingCursor = false
                this.ordersInterval = setInterval(() => {
                    this.searchNotInvoicedOrders(search)
                }, 20000)
            })

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('filteredToGenerate')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.ordersInterval)

            const search = {
                query: this.search,
                clientId: '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            // search.deliveryTodayOnly = this.deliveryTodayOnly
            // search.pickupTodayOnly = this.pickupTodayOnly
            search.dateFilter = this.dateFilter
            const drPayload = {
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'LastName',
                sortDirection: 'ASC',
            }
            this.searchDrivers(drPayload)
                .then(() => {
                    this.drivers.forEach((driver) => {
                        const name = `${driver.firstname} ${driver.lastname}`
                        const option = {
                            name,
                            value: +driver.id,
                            dbField: 'OrderStep.IdDriver',
                            isOnu: driver.onu,
                            subContractor: +driver.sub_contractor === 1,
                        }
                        this.driverOptions.push(option)
                    })
                })
                .finally(() => {
                    this.refresh += 1
                })

            this.displaySearchResults(search)
        },
    },
}
</script>
