<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs :navTabs="tabs" @navigate="changeContent($event)" /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @update="toUpdate($event, $route.name === 'vehicle_types')"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
        <!-- @deleted="deleted()" -->
    </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { useDriverStore } from '@/stores/driverStore'
import { useVehicleStore } from '@/stores/vehicleStore'

import NewVehicle from '@/views/vehicles/CreateOrUpdate.vue'
import NavTabs from '@/components/elements/NavTabs.vue'
import Vehicles from '@/views/vehicles/List.vue'
import MaintenancesList from '@/views/vehicles/MaintenancesList.vue'

export default {
    name: 'VehiclesWrapper',
    components: {
        NavTabs,
        Vehicles,
        NewVehicle,
        MaintenancesList,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name,
            ),
            tabs: [['list-ul', 'vehicles']],
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
            this.$cookies.remove('filtered')
            this.$cookies.remove('filteredMaintenancesList')
        }
    },
    mounted() {
        if (this.$route.name === 'new_vehicle') {
            this.toCreate(this.$route.name)
        } else if (!this.$route.params.id) {
            this.changeContent(this.$route.name)
        } else if (this.$route.name === 'update_vehicle') {
            this.toUpdate(this.$route.params.id)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            if (this.sideBarNav === 'new_vehicle') {
                this.toCreate(this.sideBarNav)
            } else if (!this.$route.params.id) {
                this.changeContent(this.sideBarNav)
            } else if (this.sideBarNav === 'update_vehicle') {
                this.toUpdate(this.$route.params.id)
            }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    methods: {
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useVehicleStore, ['fetchOne']),
        // deleted() {
        //     this.$toast.success(this.$t('order_delete_ok'))
        // },
        updated(event) {
            this.$toast.success(this.$t('order_update_ok'))
            this.changeContent(event)
        },

        created(event) {
            this.$toast.success(this.$t('order_create_ok'))
            this.changeContent(event)
        },

        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        toCreate(component = 'new_vehicle') {
            if (this.$route.name === 'vehicle_types') {
                component = 'new_vehicle_type'
            }
            this.componentToDisplay = this.humanize(component)
            this.$router.push({ name: component })
        },
        toUpdate(id) {
            const driversPayload = {
                query: [],
                filter: [{ dbField: 'IdVehicle', value: id }],
                perPage: 1,
                page: 1,
                sortField: 'LastName',
                sortDirection: 'ASC',
                setCurrent: true,
            }
            this.searchDrivers(driversPayload).then(() => {
                this.fetchOne(id).then(() => {
                    this.$router.push({
                        name: 'update_vehicle',
                        params: { id },
                    })
                    this.componentToDisplay = this.humanize('new_vehicle')
                })
            })
        },

        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
