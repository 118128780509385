<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <filtrable-table
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="orders"
                    :columns="columns"
                    :storeToSort="'orders'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="multiActions"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="changeUpdateSort($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    @changeSearch="changeSearch($event)"
                    @checkboxFilterUpdate="checkboxFilter($event)"
                    @dateFilterUpdate="setDateFilter($event)"
                    :hoverableItems="userStore.isB2C === false"
                    :itemActions="itemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="canAddNew()"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @assignManyToDriver="openDriverChoiceModal($event)"
                    @updatedAddress="$emit('updated', $event)"
                    @update="goToUpdateOne($event)"
                    @updateStep="goToUpdateStep($event)"
                    @updateStepDriver="updateStepsDriver($event)"
                    @updateStepStatus="updateStepStatus($event)"
                    @finishManySteps="finishManySteps($event)"
                    @packageTracking="$emit('packageTracking', $event)"
                    @download="viewImportedPDF($event)"
                >
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
                <modal
                    v-if="showConfirmModal"
                    :headerText="confirmModalHeader"
                    :buttonText="'confirm'"
                    id="deletemodal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="actionConfirmation(false)"
                    @sendFromModal="actionConfirmation(true)"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center font-semibold mt-4">
                                {{ $t(confirmModalText) }}
                            </div>
                            <span v-if="elementsToConfirm.length > 0">
                                <div
                                    v-for="(
                                        toConfirm, iToConfirm
                                    ) in elementsToConfirm"
                                    :key="iToConfirm"
                                    class="flex justify-center mt-2"
                                >
                                    <ul>
                                        <li
                                            class="flex text-sm items-center whitespace-nowrap"
                                        >
                                            <fa-icon
                                                icon="circle"
                                                class="fa-2xs mr-2"
                                            />
                                            <span
                                                v-if="toConfirm.Client"
                                                class="mr-1"
                                            >
                                                {{ toConfirm.Client }}
                                            </span>
                                            <span v-if="toConfirm.Ref">
                                                {{
                                                    ' - ' +
                                                    $t('order_ref') +
                                                    ': ' +
                                                    toConfirm.Ref
                                                }}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </span>
                            <div
                                v-if="confirmModalText2 !== ''"
                                class="flex text-xs italic items-center error-msg justify-center mt-4"
                            >
                                <fa-icon
                                    :icon="'triangle-exclamation'"
                                    class="mr-2 fa-md"
                                ></fa-icon>
                                {{ $t(confirmModalText2) }}
                            </div>

                            <div
                                v-if="confirmModalTextOptiErrorInfo !== ''"
                                class="flex items-center justify-center mt-4"
                            >
                                {{ $t(confirmModalTextOptiErrorInfo) }}
                            </div>
                            <div
                                v-if="confirmModalTextOptiError !== ''"
                                class="flex items-center justify-center mt-4"
                            >
                                {{ $t(confirmModalTextOptiError) }}
                            </div>
                        </div></template
                    ></modal
                >
                <tour-opti-warning-modal
                    v-if="showConfirmTourOptiModal"
                    :confirmModalText="confirmModalText"
                    :canBeForced="canBeForced"
                    @tourOptiConfirmation="tourOptiActionConfirmation($event)"
                ></tour-opti-warning-modal>

                <modal
                    v-if="showDrivercChoiceModal"
                    :headerText="'assign_to_driver'"
                    :buttonText="'assign'"
                    id="drivercChoiceModal"
                    :mxWidth="'w-1/3'"
                    :buttonAlign="'justify-center'"
                    @closeModal="openDriverChoiceModal([])"
                    @sendFromModal="assignDriverToMany()"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center font-semibold mt-4">
                                {{ $t('driver') }}
                            </div>
                            <driver-multiselect
                                :refreshMS="refreshMS"
                                :driverId="driverToAssignToMany"
                                :driverOptions="driverOptions"
                                @selected="
                                    driverToAssignToMany = $event.driverId
                                "
                                @clear="driverToAssignToMany = null"
                            ></driver-multiselect></div></template
                ></modal>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import DriverMultiselect from '@/components/elements/DriverMultiselect.vue'
import TourOptiWarningModal from '@/components/elements/TourOptiWarningModal.vue'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useOrderStore } from '@/stores/orderStore'
import { useTourStore } from '@/stores/tourStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
// import useVuelidate from '@vuelidate/core'
// import { required } from '@vuelidate/validators'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ContainersOnHold',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        DriverMultiselect,
        TourOptiWarningModal,
        FetchDataError,
        Modal,
    },
    // setup() {
    //     return { v$: useVuelidate() }
    // },
    data() {
        return {
            newPosition: null,
            confirmModalTextOptiErrorInfo: '',
            confirmModalTextOptiError: '',
            columns: [],
            canBeForced: true,
            driverToAssignToMany: null,
            manyStepsToAssign: null,
            showDrivercChoiceModal: false,
            showConfirmTourOptiModal: false,
            refreshMS: 0,
            confirmAction: null,
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            driverOptions: [],
            dateFilter: [],
            resetCheckedItems: false,
            // pickupTodayOnly: true,
            // deliveryTodayOnly: true,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'DateBegin',
            perPage: 100,
            page: 1,
            filter: [],
            ordersInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                },
                // { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
        }
    },
    // validations() {
    //     return {
    //         groupForm: {
    //             Name: { required },
    //             IdPricing: { required },
    //         },
    //     }
    // },
    watch: {
        // selectedTourPricing() {
        //     this.groupForm.IdPricing = this.selectedTourPricing.value
        // },
        orders(oldVal, newVal) {
            if (
                this.page === 1 &&
                oldVal !== newVal &&
                (localStorage.getItem('popoverOpen') === null ||
                    localStorage.getItem('popoverOpen') === false)
            ) {
                this.refresh += 1
            }
        },
    },

    created() {
        clearInterval(this.ordersInterval)
        const filteredList = this.$cookies.get('containersOnHoldFiltered')
        window.scrollTo(0, 0)
        this.searchingCursor = true

        this.orders = null
        this.count = null
        this.totalPages = null
        this.filter = []
        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)
                if (filteredList) {
                    // this.search.push(this.$cookies.get('query'))
                    this.search = filteredList.query
                    // this.searchQuery = this.filteredList.query[0]
                    //     ? this.filteredList.query[0].term
                    //     : ''
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    // this.pickupTodayOnly = filteredList.pickupTodayOnly
                    // this.deliveryTodayOnly = filteredList.deliveryTodayOnly

                    this.dateFilter = filteredList.dateFilter

                    this.searchByName = filteredList.searchByName
                }
                const oneYearAgo = new Date()
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
                if (!filteredList) {
                    this.dateFilter = [
                        {
                            value: oneYearAgo.toISOString().substr(0, 10),
                            dbField: 'DateBegin',
                        },
                        {
                            value: new Date().toISOString().substr(0, 10),
                            dbField: 'DateEnd',
                        },
                    ]
                }
                this.columns = [
                    {
                        name: 'client',
                        showRef: this.searchByName === false,
                        filtrable: true,
                        dbField:
                            this.searchByName === true
                                ? 'Client.Name'
                                : 'OrderClient.Reference',
                        isLink: checkAccess(
                            this.userStore,
                            this.containersOnHoldActionsAccess.update,
                        ),
                        animated: true,

                        routeName: 'update_order',
                        searchIcon:
                            this.searchByName === true ? 'user' : 'hashtag',
                        type: 'search',
                        sortable: false,
                        hide: this.userStore.isB2C
                            ? this.userStore.isB2C
                            : this.$cookies.get('is_B2C') === 'true',
                        subInfo: true,
                    },

                    //                     Date / Heure de dépose sur le site.

                    // Présence sur le site (depuis combien de temps le conteneur est présent sur le site).

                    // Deadline de restitution.
                    {
                        name: 'conteneurRef',
                        filtrable: true,
                        type: 'search',
                        sortable: false,
                        dbField: 'OrderPackage.ReferenceExternal',

                        // selected: ,
                    },

                    {
                        name: 'drivers',
                        filtrable: true,
                        sortable: false,
                        drivers: true,
                        type: 'select',
                        selected: '',

                        dbField: 'OrderStep.IdDriver',
                        options: this.driverOptions,
                        canSearch: true,
                        canUpdate: checkAccess(
                            this.userStore,
                            this.containersOnHoldActionsAccess.update,
                        ),
                        // type: 'search',
                        // dbField: 'OrderSteps.Driver.LastName',
                        hide: this.userStore.isB2C
                            ? this.userStore.isB2C
                            : this.$cookies.get('is_B2C') === 'true',
                    },
                    {
                        name: 'pick_up',
                        filtrable: false,
                        notCentered: false,
                        sortable: false,
                        dbField: 'DateBegin',
                        datePicker: true,
                        range: true,
                        withButton: false,
                        pickedDate: this.dateFilter.find(
                            (df) => df.dbField === 'DateBegin',
                        )
                            ? this.dateFilter.find(
                                  (df) => df.dbField === 'DateBegin',
                              ).value
                            : oneYearAgo.toISOString().substr(0, 10),
                        // buttonValue: this.pickupTodayOnly,
                        canUpdate: checkAccess(
                            this.userStore,
                            this.containersOnHoldActionsAccess.update,
                        ),
                        hide: this.userStore.isB2C
                            ? this.userStore.isB2C
                            : this.$cookies.get('is_B2C') === 'true',
                    },
                    {
                        name: 'delivery',
                        filtrable: false,
                        notCentered: false,
                        withButton: false,
                        range: true,

                        canUpdate: checkAccess(
                            this.userStore,
                            this.containersOnHoldActionsAccess.update,
                        ),
                        // buttonValue: this.deliveryTodayOnly,
                        dbField: 'DateEnd',
                        datePicker: true,
                        pickedDate: this.dateFilter.find(
                            (df) => df.dbField === 'DateEnd',
                        )
                            ? this.dateFilter.find(
                                  (df) => df.dbField === 'DateEnd',
                              ).value
                            : new Date(),
                        sortable: false,
                        hide: this.userStore.isB2C
                            ? this.userStore.isB2C
                            : this.$cookies.get('is_B2C') === 'true',
                    },
                    {
                        name: 'onHoldSince',
                        filtrable: false,
                        sortable: false,
                    },
                    {
                        name: 'elapsed',
                        filtrable: false,
                        sortable: false,
                    },

                    // { name: 'deadline', filtrable: false, sortable: false },
                ]
                if (this.userStore.isB2C === false) {
                    clearInterval(isB2CInterval)

                    // if (this.userStore.userUniverses.length === 1) {
                    // this.selectedUniverses = structuredClone(
                    //     this.userStore.userUniverses
                    // )
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            const universeFilter = {
                                dbField: 'OrderClient.IdUniverse',
                                value:
                                    this.selectedUniverses.length > 0
                                        ? this.selectedUniverses
                                        : this.universesOptions,
                            }
                            if (
                                !this.filter.find(
                                    (f) =>
                                        f.dbField === 'OrderClient.IdUniverse',
                                )
                            ) {
                                this.filter.push(universeFilter)
                            }
                            const driverUniverses = []

                            universeFilter.value.forEach((universe) => {
                                driverUniverses.push(universe.value)
                            })
                            // }
                            const payload = {
                                query: [],
                                filter: [],
                                perPage: 10000,
                                page: 1,
                                sortField: 'Name',
                                sortDirection: 'ASC',
                            }
                            this.searchContainers(payload)
                            this.availableDriversForDate({
                                date: new Date().toISOString().slice(0, 10),
                                universes: driverUniverses,
                            }).then((res) => {
                                this.driverOptions = []
                                this.driverOptions.push({
                                    name: this.$t('no_driver'),
                                    value: 0,
                                    dbField: 'OrderStep.IdDriver',
                                    isOnu: false,
                                    subContractor: false,
                                })
                                if (res && res.status === 200 && res.data) {
                                    res.data.forEach((driver) => {
                                        const name = `${driver.FirstName} ${driver.LastName}`
                                        const option = {
                                            name,
                                            value: +driver.Id,
                                            dbField: 'OrderStep.IdDriver',

                                            isOnu: +driver.ONU === 1,
                                            subContractor:
                                                +driver.SubContractor === 1,
                                        }
                                        this.driverOptions.push(option)
                                    })
                                    const driverColumn = this.columns.find(
                                        (col) => col.name === 'drivers',
                                    )
                                    driverColumn.options = this.driverOptions
                                }
                            })

                            // this.setResults()
                        }

                        this.eventBus.on('trigger-universe-search', (param) => {
                            this.searchCustomUniverses(param)
                        })
                        this.eventBus.on(
                            'remove-from-selected-universe',
                            (index) => {
                                this.removeFromSelectedUniverses(index)
                            },
                        )
                    }, 10)

                    this.setResults()
                }
            }
        }, 20)
    },

    unmounted() {
        this.orders = null
        this.count = null
        this.totalPages = null
        clearInterval(this.ordersInterval)
        this.eventBus.all.clear()
    },

    computed: {
        containersOnHoldActionsAccess() {
            return actionsAccess.orders.containersOnHold
        },
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        multiActions() {
            const actions = []
            if (this.userStore.isB2C === false) {
                if (
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.assign.driver,
                    )
                ) {
                    actions.push({
                        name: 'assign_to_driver',
                        action: 'assignManyToDriver',
                        icon: 'truck-fast',
                    })
                }
                if (
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.finishManySteps,
                    )
                ) {
                    actions.push({
                        name: 'finish_many_steps',
                        action: 'finishManySteps',
                        icon: 'check',
                    })
                }
            }
            return actions
        },
        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.ordersActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                    hide: false,
                })
            }
            return actions
        },
        ...mapWritableState(useOrderStore, {
            orders: 'containersOnHold',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore, useOrderStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        canAddNew() {
            return false
        },
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),

        ...mapActions(useTourStore, ['moveManyDailySteps']),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
        }),
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
            'fetchOne',
        ]),
        ...mapActions(useOrderStore, [
            'searchOrdersWithContainersOnHold',
            'moveForManyOrders',
            'updateStep',
            'moveForManyOrders',
            'updateMultiStepsStatus',
        ]),

        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        goToUpdateOne(id) {
            clearInterval(this.ordersInterval)
            this.$emit('goToUpdateOrder', id)
        },
        goToUpdateStep(event) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateStep', event)
        },
        finishManySteps(orders = []) {
            this.updateMultiStepsStatus(orders)
                .then((res) => {
                    // console.log(res)
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('error_occured'))
                    this.setResults()
                })
        },
        changeSearch(columnIndex = null) {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Client.Name') {
                    this.search[index].dbField = 'OrderClient.Reference'
                } else if (filter.dbField === 'OrderClient.Reference') {
                    this.search[index].dbField = 'Client.Name'
                }
            })
            if (columnIndex !== null) {
                this.columns[columnIndex] = {
                    name:
                        this.searchByName === false
                            ? 'client_n_reference'
                            : 'client',
                    showRef: this.searchByName === false,
                    filtrable: true,
                    dbField:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                    isLink: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update,
                    ),
                    routeName: 'update_order',
                    searchIcon: this.searchByName === true ? 'user' : 'hashtag',
                    type: 'search',
                    animated: true,
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: this.searchByName === true,
                }
                this.refresh += 1
            }
            this.setResults()
        },

        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },

        openDriverChoiceModal(orders = []) {
            this.manyStepsToAssign = null
            this.driverToAssignToMany = null
            if (orders.length > 0) {
                const steps = []

                this.orders.forEach((order) => {
                    orders.forEach((oToModify) => {
                        if (+order.id === +oToModify) {
                            order.currentStepsToDisplay.forEach((step) => {
                                steps.push(step)
                            })
                        }
                    })
                })
                this.manyStepsToAssign = {
                    steps,
                    newDriver: 0,
                    isRecurrent: this.$route.name === 'recurrent_orders',
                }
            }

            this.showDrivercChoiceModal = !this.showDrivercChoiceModal
        },
        assignDriverToMany() {
            this.manyStepsToAssign.newDriver = +this.driverToAssignToMany

            this.updateStepsDriver(this.manyStepsToAssign, false, false, true)
        },
        viewImportedPDF(file) {
            let apiUrl = `/uploads/orderFiles/${file.Name}`
            if (file.Type && file.Type === 'user') {
                apiUrl = `/uploads/order/userUploads/${file.Name}`
            }
            if (file.Type && file.Type === 'slip') {
                apiUrl = `/uploads/order/transportSplit/${file.Name}`
            }
            if (file.Type && file.Type === 'charter') {
                apiUrl = `/uploads/orderCharters/${file.Name}` /// uploads/order/transportSplit/${file.Name}`
            }
            this.axios
                .get(apiUrl, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session',
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.target = '_external'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    // URL.revokeObjectURL(url)
                    // link.setAttribute('download', file)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                })
        },
        updateStepStatus(step) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)

            const clone = structuredClone(step)
            if (step.newStatus !== 'executed') {
                clone.step.ExecutedDateTime = null
            }

            clone.step.Status = step.newStatus
            clone.step.ModificationUserId = this.userStore.current.id
            const payload = {
                id: clone.step.Id,
                form: clone.step,
            }
            this.updateStep(payload)
                .then((res) => {
                    step.step.Status = step.newStatus
                    step.step.ModificationUserId = this.userStore.current.id
                    const orderInUpdate = this.orders.find(
                        (ord) => ord.id === +step.step.IdOrder,
                    )
                    const updatedStepIndex = orderInUpdate.steps.findIndex(
                        (stp) => +stp.Id === +step.step.Id,
                    )
                    if (step.newStatus !== 'executed') {
                        step.step.ExecutedDateTime = null
                        if (
                            step.updateStepAfter &&
                            step.updateStepAfter === true
                        ) {
                            orderInUpdate.steps.forEach(
                                (stpToUpdate, index) => {
                                    if (updatedStepIndex < index) {
                                        stpToUpdate.Status = stpToUpdate.Driver
                                            ? 'affected'
                                            : 'new'
                                        stpToUpdate.ExecutedDateTime = null
                                        stpToUpdate.ModificationUserId =
                                            this.userStore.current.id
                                        const payloadStepAfter = {
                                            id: stpToUpdate.Id,
                                            form: stpToUpdate,
                                        }
                                        this.updateStep(payloadStepAfter)
                                    }
                                },
                            )
                        }
                        // TODO BUG 550 Status of undefined qudn on utilise le switch sur le scond step
                        // fix déployer mais à revoir
                        // else if (
                        //     updatedStepIndex + 1 <
                        //     orderInUpdate.steps.length
                        // ) {
                        //     console.log('coucou')
                        //     const stpToUpdate =
                        //         orderInUpdate.steps[updatedStepIndex + 1]

                        //     stpToUpdate.Status = 'inProgress'
                        //     stpToUpdate.ExecutedDateTime = null
                        //     stpToUpdate.ModificationUserId =
                        //         this.userStore.current.id
                        //     const payloadStepAfter = {
                        //         id: stpToUpdate.Id,
                        //         form: stpToUpdate,
                        //     }
                        //     this.updateStep(payloadStepAfter).then((res) => {
                        //         this.fetchOne(this.$route.params.id)
                        //     })
                        // }
                    }

                    this.setResults()
                })
                .catch((err) => {
                    this.searchingCursor = false
                    if (err.data && err.data.status === 400) {
                        this.$toast.error(this.$t('previous_step_not_done'))
                    } else {
                        // this.$toast.error(this.$t('error_occured'))
                    }
                })
        },
        updateStepsDriver(
            infos,
            acceptUnassigned = false,
            force = false,
            moveForManyOrders = false,
        ) {
            this.canBeForced = true
            if (this.showDrivercChoiceModal === true) {
                this.openDriverChoiceModal()
            }
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            const stepsToMove = []
            infos.steps.forEach((step, index) => {
                step.IdDriver = infos.newDriver !== null ? infos.newDriver : 0
                step.Status = step.Status === 'new' ? 'affected' : step.Status
                const payload = {
                    id: step.Id,
                    form: step,
                }
                if (!infos.isRecurrent) {
                    stepsToMove.push(step.Id)
                } else {
                    this.updateRecurrentStep(payload)
                        .then((res) => {
                            if (index === infos.steps.length - 1) {
                                this.setResults()
                            }
                        })
                        .catch((err) => {
                            this.searchingCursor = false
                            this.$toast.error(this.$t('error_occured'))
                        })
                }
            })
            if (!infos.isRecurrent) {
                const date = infos.steps[0].FromDateTime
                    ? infos.steps[0].FromDateTime
                    : infos.steps[0].BeforeDateTime
                const payload = {
                    IdOrderStepEnd: stepsToMove,
                    IdTour: 0,
                    ModificationUserId: this.userStore.current.id,
                    IdDriver: infos.newDriver !== null ? infos.newDriver : 0,
                    Date: date ? date.slice(0, 10) : null,
                    acceptUnassigned,
                    Position: this.newPosition,
                    force,
                    // IdTour: this.current.Id,
                }

                const callToUse = 'moveManyDailySteps'
                // à remettre si fred fait un autre call
                // moveForManyOrders === true
                //     ? 'moveForManyOrders'
                //     : 'moveManyDailySteps'
                this[callToUse](payload)
                    .then((res) => {
                        this.newPosition = null
                        // if (index === infos.steps.length - 1) {
                        this.setResults()
                        // }
                    })
                    .catch((err) => {
                        if (err.response.status === 400) {
                            if (
                                err.response.data.message !==
                                    'tour_opti_error_steps_not_assigned' &&
                                err.response.data.message !==
                                    'tour_opti_error_forced'
                            ) {
                                this.canBeForced = false
                            }
                            this.searchingCursor = true
                            this.confirmAction = null
                            this.showConfirmTourOptiModal = true
                            this.confirmModalText = err.response.data.message
                            this.elementsToConfirm = []

                            const confirmInterval = setInterval(async () => {
                                if (this.confirmAction !== null) {
                                    clearInterval(confirmInterval)
                                    this.elementsToConfirm = []
                                    if (this.confirmAction === true) {
                                        this.confirmAction = null
                                        acceptUnassigned =
                                            err.response.data.message ===
                                            'tour_opti_error_steps_not_assigned'
                                        force =
                                            err.response.data.message !==
                                            'tour_opti_error_steps_not_assigned'
                                        this.updateStepsDriver(
                                            infos,
                                            acceptUnassigned,
                                            force,
                                            true,
                                        )
                                    } else {
                                        this.confirmAction = null
                                        this.confirmModalTextOptiErrorInfo = ''
                                        this.confirmModalTextOptiError = ''
                                        this.searchingCursor = false
                                        this.setResults()

                                        this.$toast.info(
                                            this.$t('action_canceled'),
                                        )
                                    }
                                }
                            }, 150)
                        } else {
                            this.searchingCursor = false

                            this.$toast.error(this.$t('error_occured'))
                        }
                    })
            }
        },

        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmModal = false
        },
        tourOptiActionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete.action
            this.newPosition = confirmDelete.newPosition

            this.searchingCursor = confirmDelete.action
            this.showConfirmTourOptiModal = false
        },
        setDateFilter(dFilter) {
            this.searchingCursor = true
            this.$cookies.remove('containersOnHoldFiltered')

            const filterExistsIndex = this.dateFilter.findIndex(
                (df) => df.dbField === dFilter.dbField,
            )
            if (filterExistsIndex !== -1) {
                this.dateFilter[filterExistsIndex] = dFilter
            } else {
                this.dateFilter.slice(filterExistsIndex, 1)
                this.dateFilter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }

            this.setResults()
        },
        setPage(isNext) {
            clearInterval(this.ordersInterval)
            this.$cookies.remove('containersOnHoldFiltered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            this.$cookies.remove('containersOnHoldFiltered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.refresh += 1
            search.searchByName = this.searchByName

            // const query = search.query

            this.$cookies.set('containersOnHoldFiltered', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''
            if (this.userStore.isB2C === true) {
                search.myCart = this.$route.name === 'my_cart'

                clearInterval(this.ordersInterval)
                this.searchDeliveriesByClient(search).then(() => {
                    this.searchingCursor = false

                    this.ordersInterval = setInterval(() => {
                        this.searchDeliveriesByClient(search)
                    }, 20000)
                })
            } else {
                this.refresh += 1

                clearInterval(this.ordersInterval)
                this.searchOrdersWithContainersOnHold(search).then(() => {
                    this.searchingCursor = false

                    this.ordersInterval = setInterval(() => {
                        this.searchOrdersWithContainersOnHold(search)
                    }, 20000)
                })
            }
        },
        changeUpdateSort(sortField) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('containersOnHoldFiltered')
            this.searchingCursor = true
            if (this.sortField === sortField) {
                this.sortDirection =
                    this.sortDirection === 'ASC' ? 'DESC' : 'ASC'
            } else {
                this.sortDirection = 'ASC'
                this.sortField = sortField
            }
            this.setResults()
        },
        changePerPage(perPage) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('containersOnHoldFiltered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.ordersInterval)
            const search = {
                query: this.search,
                clientId:
                    this.userStore.isB2C === true
                        ? this.userStore.current.idClient
                        : '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            if (this.userStore.isB2C === false) {
                // search.deliveryTodayOnly = this.deliveryTodayOnly
                // search.pickupTodayOnly = this.pickupTodayOnly
                search.dateFilter = this.dateFilter
            }
            const uniFilter = this.filter.find(
                (filt) => filt.dbField === 'OrderClient.IdUniverse',
            )
            const universes = []
            if (uniFilter) {
                uniFilter.value.forEach((uni) => {
                    universes.push(uni.value)
                })
            }
            const driverFilter = this.filter.find(
                (filt) => filt.dbField === 'OrderStep.IdDriver',
            )
            if (!driverFilter && this.userStore.isB2C === false) {
                if (this.dateFilter.length === 0) {
                    const drPayload = {
                        query: [],
                        filter: [
                            {
                                dbField: 'Active',
                                value: 1,
                            },
                            {
                                dbField: 'UserUniverse.IdUniverse',
                                value: universes,
                            },
                        ],
                        perPage: 10000,
                        page: 1,
                        sortField: 'LastName',
                        sortDirection: 'ASC',
                    }
                    this.driverOptions = []
                    this.searchDrivers(drPayload)
                        .then(() => {
                            this.driverOptions = []

                            this.driverOptions.push({
                                name: this.$t('no_driver'),
                                value: 0,
                                dbField: 'OrderStep.IdDriver',
                                isOnu: false,
                                subContractor: false,
                            })
                            this.drivers.forEach((driver) => {
                                const name = `${driver.firstname} ${driver.lastname}`
                                const option = {
                                    name,
                                    value: +driver.id,
                                    dbField: 'OrderStep.IdDriver',
                                    isOnu: +driver.onu === 1,
                                    subContractor: +driver.SubContractor === 1,
                                }
                                this.driverOptions.push(option)
                            })
                        })
                        .finally(() => {
                            this.refresh += 1
                        })
                } else {
                    this.driverOptions = []

                    this.availableDriversForDate({
                        date: this.dateFilter[0].value,
                        universes,
                    })
                        .then((res) => {
                            this.driverOptions = []

                            this.driverOptions.push({
                                name: this.$t('no_driver'),
                                value: 0,
                                dbField: 'OrderStep.IdDriver',
                                isOnu: false,
                                subContractor: false,
                            })
                            if (res && res.status === 200 && res.data) {
                                res.data.forEach((driver) => {
                                    const name = `${driver.FirstName} ${driver.LastName}`
                                    const option = {
                                        name,
                                        value: +driver.Id,
                                        dbField: 'OrderStep.IdDriver',
                                        isOnu: +driver.ONU === 1,
                                        subContractor:
                                            +driver.SubContractor === 1,
                                    }
                                    this.driverOptions.push(option)
                                })
                            }
                        })
                        .finally(() => {
                            this.refresh += 1
                        })
                }
            }

            this.displaySearchResults(search)
        },
    },
}
</script>
