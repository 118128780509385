<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'pricing_params'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="pricingParamsList"
                :items="pricingParams"
                :columns="columns"
                :storeToSort="'pricingParams'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-1/3'"
            :headerText="isUpdate ? 'update_param' : 'add_param'"
            @sendFromModal="addParam()"
            @closeModal="openModal(null)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center italic my-4">
                        {{ $t('pricing_param_modal_info') }}
                    </div>
                    <!-- <div
                                class="grid grid-cols-6 gap-2 items-center my-2"
                            > -->
                    <div class="justify-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 mb-2 pb-1 mr-2"
                            >{{ $t('title') + ' *' }}</label
                        >
                        <input
                            :id="`paramTitle`"
                            type="text"
                            v-model="paramForm.Title"
                            class="border rounded px-3 py-2 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.paramForm.Title.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <!-- <span class="col-span-3 justify-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mb-1 mr-2"
                                        >{{ $t('group_date') }}</label
                                    >
                                    <input
                                        :id="`groupDate`"
                                        type="date"
                                        disabled
                                        v-model="paramForm.DateTour"
                                        class="border rounded px-3 py-2 w-full"
                                    />
                                </span> -->
                    <!-- </div> -->
                    <div class="justify-center my-2">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >{{ $t('type') + ' *' }}</label
                        >
                        <Multiselect
                            id="paramTYpeMS"
                            class="darky"
                            v-model="paramForm.Type"
                            :options="paramTypeOptions"
                            label="name"
                            track-by="name"
                            :searchable="false"
                            :can-clear="false"
                            :can-deselect="false"
                            :allow-empty="false"
                        >
                        </Multiselect>
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.paramForm.Type.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-6 gap-2 my-2 items-center">
                        <span class="col-span-3 justify-center">
                            <label
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                                >{{ $t('min') + ' *' }}</label
                            >
                            <input
                                :id="`min`"
                                type="number"
                                min="0"
                                step="0.5"
                                v-model="paramForm.Min"
                                class="border rounded px-3 py-2 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 ml-2 mb-2"
                                v-for="error of v$.paramForm.Min.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                        <span class="col-span-3 justify-center">
                            <label
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                                >{{ $t('max')+ ' *' }}</label
                            >
                            <input
                                :id="`max`"
                                type="number"
                                v-model="paramForm.Max"
                                min="0"
                                step="0.5"
                                class="border rounded px-3 py-2 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 ml-2 mb-2"
                                v-for="error of v$.paramForm.Max.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                    </div>

                    <!-- <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div> -->
                </div>
            </template></modal
        >

        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.title" class="mr-1">
                                    {{ `${toConfirm.title} ` }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { usePricingStore } from '@/stores/pricingStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapStores, mapWritableState } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PricingParamsList',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            isUpdate: false,
            show: false,
            entityTypeToDelete: 'pricing_params_tc',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            pricingParamsInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            newFromParam: false,
            paramForm: {
                Id: '',
                Title: '',
                Type: '',
                Min: '',
                Max: '',

            },
            paramTypeOptions: [
                {
                    name: this.$t('km'),
                    value: 'km',
                    dbField: 'Type',
                },
                {
                    name: this.$t('vol'),
                    value: 'volume',
                    dbField: 'Type',
                },

                {
                    name: this.$t('param_size'),
                    value: 'size',
                    dbField: 'Type',
                },
                {
                    name: this.$t('weight'),
                    value: 'weight',
                    dbField: 'Type',
                },
            ],
        }
    },
    watch: {
        pricingParams() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    validations() {
        return {
            paramForm: {
                Title: { required },
                Type: { required },
                Min: { required },
                Max: { required },
            },
        }
    },
    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredPricingParams')
        this.columns = [
            {
                name: 'title',
                filtrable: true,
                dbField: 'Title',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.pricingParamsActionAccess.update,
                ),
            },

            {
                name: 'type',
                filtrable: true,
                type: 'select',
                dbField: 'Type',
                sortable: false,
                optionAlreadyTranslated: true,
                options: this.paramTypeOptions,
            },
            {
                name: 'min',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'max',
                filtrable: false,
                sortable: false,
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.pricingParamsInterval)
    },
    computed: {
        pricingParamsActionAccess() {
            return actionsAccess.pricings.params
        },
        ...mapWritableState(usePricingStore, {
            currentPricingParam: 'currentPricingParam',
            pricingParams: 'pricingParams',
            count: 'pricingParamsCount',
            fetchError: 'fetchError',
            totalPages: 'paramsTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.pricingParamsActionAccess.update,
                )
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            // TODO add this action when the dock is created + ADD AUTH

            if (
                checkAccess(
                    this.userStore,
                    this.pricingParamsActionAccess.delete,
                )
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.pricingParamsActionAccess.delete,
                )
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteMany',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(usePricingStore, [
            'searchPricingParams',
            'fetchOnePricingParam',
            'createPricingParam',
            'updatePricingParam',
            'deletePricingParam',
            'deleteManyPricingParams',
        ]),
        upFilter(event) {
            clearInterval(this.pricingParamsInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.pricingParamsActionAccess.create,
            )
        },
        openModal(id = null) {
            clearInterval(this.pricingParamsInterval)
            this.isUpdate = false

            if (id !== null) {
                const pricingToUpdate = this.pricingParams.find(
                    (p) => p.id === id,
                )

                this.paramForm = {
                    Id: pricingToUpdate.id,
                    Title: pricingToUpdate.title,
                    Type: pricingToUpdate.realType,
                    Min: pricingToUpdate.min,
                    Max: pricingToUpdate.max,
                }
                this.isUpdate = true
            } else {
                this.paramForm = {
                    Id: '',
                    Title: '',
                    Type: '',
                    Min: '',
                    Max: '',
                }
            }
            this.show = !this.show
        },
        async addParam() {
            this.v$.paramForm.$reset()
            const isValid = await this.v$.paramForm.$validate()
            if (isValid === true) {
                if (this.isUpdate) {
                    this.updatePricingParam(this.paramForm)
                        .then((res) => {
                            this.$toast.success(this.$t('update_ok'))
                            this.setResults()
                            this.openModal(null)
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('update_ko'))
                        })
                } else {
                    this.createPricingParam(this.paramForm)
                        .then(() => {
                            this.$toast.success(this.$t('create_ok'))
                            this.openModal(null)
                            this.setResults()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('create_ko'))
                        })
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.pricingParamsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.entityTypeToDelete = 'pricing_params_tc'

            const pricing = this.pricingParams.find((h) => h.id === id)
            this.elementsToConfirm.push(pricing)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deletePricingParam(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.pricingParamsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'pricing_params_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const pricing = this.pricingParams.find((h) => h.id === id)
                this.elementsToConfirm.push(pricing)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyPricingParams(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.pricingParamsInterval)
            this.$cookies.remove('filteredPricingParams')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.pricingParamsInterval)
            this.$cookies.remove('filteredPricingParams')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchPricingParams(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredPricingParams', search)
            this.pricingParamsInterval = setInterval(() => {
                this.searchPricingParams(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.pricingParamsInterval)
            this.$cookies.remove('filteredPricingParams')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
