<template>
    <div class="mr-20 bg-white darky">
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import Invoices from '@/views/billings/List.vue'
import Invoicings from '@/views/billings/ToGenerateList.vue'
import UnpaidInvoices from '@/views/billings/UnpaidInvoices.vue'

export default {
    name: 'BillingsWrapper',
    components: {
        Invoices,
        Invoicings,
        UnpaidInvoices,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filteredToGenerate')
        this.$cookies.remove('filteredGenerated')

        this.$cookies.remove('filteredUnpaid')
        }
    },
    mounted() {
        this.changeContent(this.$route.name)
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            // TODO voir quand autres pages accessibles depuis sidebar ne pas remove si on va sur des updates
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filteredToGenerate')
                this.$cookies.remove('filteredGenerated')

                this.$cookies.remove('filteredPaid')
            }

            this.changeContent(this.sideBarNav)
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        changeContent(tab) {
            this.$emit('removeSidebarNav')
            if (tab === 'my_invoices') {
                tab = 'invoices'
            }
            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
