<template>
    <div class="bg-white darky" :class="$route.meta.map ? '' : 'mr-20'">
        <!-- <nav-tabs :navTabs="tabs" @navigate="changeContent($event)" /> -->
        <component
            :is="componentToDisplay"
            :key="$route.fullPath"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @goToSimulation="toSimulation($event)"
            @simulationView="simulationView($event)"
            @tourView="tourView($event)"
            @goToTour="toTour($event)"
        />
    </div>
</template>
<script>
import NavTabs from '@/components/elements/NavTabs.vue'
import { useDriverStore } from '@/stores/driverStore'
import { useSimulationStore } from '@/stores/simulationStore'
import { useTourStore } from '@/stores/tourStore'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import Tours from '@/views/tours/List.vue'
import MapView from '@/views/tours/MapView.vue'
import SimulationDetail from '@/views/tours/SimulationDetail.vue'
import SimulationGeneration from '@/views/tours/SimulationGeneration.vue'
import Simulations from '@/views/tours/SimulationsList.vue'
import TourDetail from '@/views/tours/TourDetail.vue'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    name: 'ToursWrapper',
    components: {
        NavTabs,
        Tours,
        Simulations,
        SimulationGeneration,
        SimulationDetail,
        MapView,
        TourDetail,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'tours'],
                // ['cart-plus', 'new_order'],
                ['list-ul', 'simulations'],
            ],
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filtered')
        }
    },
    mounted() {
        this.$emit('removeSidebarNav')

        if (this.$route.name === 'simulation_view') {
            this.simulationView(this.$route.params.id)
        } else if (this.$route.name === 'tour_view') {
            this.tourView(this.$route.params.id)
        } else {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            this.changeContent(this.sideBarNav)
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useTourStore),
    },
    methods: {
        ...mapActions(useSimulationStore, ['fetchOneSimulation']),
        ...mapActions(useTourStore, ['fetchOneTour', 'fetchDaily']),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useVehicleStore, {
            searchVehicles: 'search',
        }),
        toSimulation(id) {
            this.$router
                .push({ name: 'simulation_detail', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('simulation_detail')
                })
        },
        simulationView(id) {
            // this.fetchOneSimulation({ id }).then(() => {
            this.$router
                .push({ name: 'simulation_view', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('map_view')
                })
            // })
        },
        tourView(id) {
            // this.fetchOneTour({ id }).then(() => {
            this.$router
                .push({ name: 'tour_view', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('map_view')
                })
            // })
        },
        // toDaily() {
        //     this.tourStore.$patch({ dailyUnaffected: [] })
        //     // this.fetchDaily().then(() => {
        //         this.componentToDisplay = this.humanize('daily_view')
        //     // })
        // },
        toTour(id) {
            this.$router
                .push({ name: 'tour_detail', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('tour_detail')
                })
        },
        // deleted() {
        //     this.$toast.success(this.$t('order_delete_ok'))
        // },

        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        toCreate() {
            this.componentToDisplay = this.humanize('simulation_generation')
            this.$router.push({ name: 'simulation_generation' })
        },
        // toUpdate(id) {
        //     this.fetchOne(id)
        //     this.$router.push({ name: 'update_order', params: { id } })
        //     this.componentToDisplay = this.humanize('new_order')
        // },

        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
