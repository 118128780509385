<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs :navTabs="tabs" @navigate="changeContent($event)" /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @goToUpdatedriver="toUpdate($event)"
            @goToDriverPlanning="toPlanning($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import { mapActions } from 'pinia'
import NavTabs from '@/components/elements/NavTabs.vue'
import Drivers from '@/views/drivers/List.vue'
import NewDriver from '@/views/drivers/CreateOrUpdate.vue'
import WeeklyPlanning from '@/views/drivers/WeeklyPlanning.vue'
import DriverPlanning from '@/views/drivers/DriverPlanning.vue'
import { useDriverStore } from '@/stores/driverStore'
import { useAllUsersStore } from '@/stores/allUsersStore'

export default {
    name: 'DriversWrapper',
    components: {
        NavTabs,
        Drivers,
        WeeklyPlanning,
        NewDriver,
        DriverPlanning,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'drivers'],
                ['calendar', 'weekly_planning'],
            ],
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filtered')
        }
    },
    mounted() {
        if (this.$route.name === 'new_driver') {
            this.toCreate()
        } else if (!this.$route.params.id) {
            this.changeContent(this.$route.name)
        } else if (this.$route.name === 'update_driver') {
            this.toUpdate(this.$route.params.id)
        } else if (this.$route.name === 'driver_planning') {
            this.toPlanning(this.$route.params.id)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            if (this.sideBarNav === 'new_driver') {
                this.toCreate()
            } else if (!this.$route.params.id) {
                this.changeContent(this.sideBarNav)
            } else if (this.sideBarNav === 'update_driver') {
                this.toUpdate(this.$route.params.id)
            } else if (this.sideBarNav === 'driver_planning') {
                // 
                this.toPlanning(this.$route.params.id)
            }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(useDriverStore, ['fetchOne', 'fetchDriverPlanning']),
        ...mapActions(useAllUsersStore, { searchUsers: 'search' }),

        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        toCreate() {
            this.$emit('removeSidebarNav')
            this.searchUsers({
                query: [],
                filter: [{ dbField: 'Type', value: 'driver' }],
                sortDirection: 'ASC',
                sortField: 'LastName',
                perPage: 200,
                page: 1,
            })
            this.componentToDisplay = this.humanize('new_driver')
            this.$router.push({ name: 'new_driver' })
        },
        toUpdate(id) {
            this.$emit('removeSidebarNav')
            this.searchUsers({
                query: [],
                filter: [{ dbField: 'Type', value: 'driver' }],
                sortDirection: 'ASC',
                sortField: 'LastName',
                perPage: 200,
                page: 1,
            })
            this.fetchOne(id)
            this.componentToDisplay = this.humanize('new_driver')
            this.$router.push({ name: 'update_driver', params: { id } })
        },
        toPlanning(id) {
            this.$emit('removeSidebarNav')

            this.fetchOne(id).then((res) => {
                this.fetchDriverPlanning({
                    idUser: res.data.IdUser,
                })
                this.componentToDisplay = this.humanize('driver_planning')
                this.$router.push({
                    name: 'driver_planning',
                    params: { id },
                })
            })
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
