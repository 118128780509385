<template>
    <div class="mx-auto bg-white">
        <stepper
            :key="refresh"
            :givenStep="currentStep"
            :totalStepsCount="[1, 2, 3, 4]"
            @next="currentStep += 1"
            @prev="currentStep -= 1"
            @submit="savePricing()"
            @cancel="resetForm()"
        >
            <template v-if="currentStep === 1" #step1>
                <div class="space-y-4">
                    <h2 class="text-2xl font-bold mb-1">
                        {{
                            `${$t('pricing')} ${
                                isDuplicate === true
                                    ? ' - (' + $t('copy_noun') + ')'
                                    : ''
                            }`
                        }}
                    </h2>
                    <hr class="mb-2" />
                    <div class="grid grid-cols-2 gap-4">
                        <div class="col-span-2">
                            <Multiselect
                                v-if="!isUpdate && !isDuplicate"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="pricingForm.Id"
                                :options="pricingOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_one')"
                                :allow-empty="true"
                                :can-clear="true"
                                @search-change="searchPricingsQuery($event)"
                                @select="setPricing($event)"
                                @clear="setPricing()"
                            ></Multiselect>
                            <!-- <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="noHarborError"
                            >
                                <div class="error-msg">
                                    {{ $t('no_results') }}
                                </div>
                            </div> -->
                        </div>
                        <div
                            v-if="!isUpdate"
                            class="col-span-2 text-base font-bold"
                        >
                            {{ $t('or') + ' ' + $t('create') }}
                        </div>
                        <div>
                            <label class="block mb-2">{{ $t('name') }}</label>
                            <input
                                v-model="pricingForm.Name"
                                @blur="v$.pricingForm.Name.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.pricingForm.Name.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{ $t('type') }}</label>
                            <Multiselect
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="pricingForm.Type"
                                :options="[
                                    {
                                        name: $t('order_price_param'),
                                        value: 'order',
                                    },
                                    { name: $t('order_group'), value: 'tour' },
                                ]"
                                label="name"
                                track-by="name"
                                :placeholder="$t('select_one')"
                                :allow-empty="false"
                                :can-clear="false"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.pricingForm.Type.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('date_begin')
                            }}</label>
                            <input
                                type="date"
                                :min="new Date().toISOString().substr(0, 10)"
                                v-model="pricingForm.DateBegin"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.pricingForm.DateBegin
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('date_end')
                            }}</label>
                            <input
                                type="date"
                                :min="pricingForm.DateBegin"
                                v-model="pricingForm.DateEnd"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                        </div>
                    </div>
                    <div class="flex items-center pt-4 justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="pricingForm.Active"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('activate_pricing') }}</label
                        >
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 2" #step2>
                <div class="space-y-4">
                    <div class="grid grid-cols-6 gap-4 mt-10">
                        <div class="col-span-2">
                            <pricing-summary
                                :key="refresh"
                                :summary="pricingForm"
                            ></pricing-summary>
                        </div>
                        <div class="col-span-4">
                            <h2
                                class="text-2xl flex justify-start font-bold mb-1"
                            >
                                {{
                                    `${$t('clients')} ${
                                        isDuplicate === true
                                            ? ' - (' + $t('copy_noun') + ')'
                                            : ''
                                    }`
                                }}
                            </h2>
                            <hr class="mb-3" />

                            <Multiselect
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                :options="clientOptions"
                                :hide-selected="true"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_clients')"
                                :allow-empty="false"
                                :can-clear="false"
                                :object="true"
                                @search-change="searchClientsQuery($event)"
                                @select="selectClient($event)"
                            ></Multiselect>
                            <span
                                v-if="selectedClients.length > 0"
                                class="my-3 grid gap-1 grid-cols-4"
                            >
                                <div
                                    v-for="(
                                        selectedClient, index
                                    ) in selectedClients"
                                    :key="index"
                                    class="text-xs col-span-1 flex items-center overflow-auto"
                                >
                                    <span
                                        class="border-2 rounded p-2 w-full h-full"
                                    >
                                        <div class="flex justify-end">
                                            <fa-icon
                                                icon="trash-can"
                                                :title="$t('remove')"
                                                class="ml-2 cursor-pointer error-msg"
                                                @click.prevent="
                                                    selectClient(
                                                        selectedClient,
                                                        index,
                                                    )
                                                "
                                            ></fa-icon>
                                        </div>
                                        <div
                                            class="mb-1 font-semibold"
                                            :title="$t('name')"
                                        >
                                            {{ `${selectedClient.Name}` }}
                                            <div
                                                class="flex items-center pt-2 justify-center"
                                            >
                                                <input
                                                    :id="
                                                        'defP-checkbox' + index
                                                    "
                                                    type="checkbox"
                                                    v-model="
                                                        selectedClient.DefaultPricing
                                                    "
                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                />
                                                <label
                                                    for="set-active-checkbox"
                                                    class="ml-2 font-medium"
                                                    >{{
                                                        $t('default_pricing')
                                                    }}</label
                                                >
                                            </div>
                                        </div></span
                                    >
                                </div></span
                            >
                            <span v-if="allClients === true">
                                <div
                                    class="italic my-3 flex justify-center items-center theme-color"
                                >
                                    {{ $t('all_clients_selected') }}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 3" #step3>
                <div class="space-y-4">
                    <div class="grid grid-cols-6 gap-4 mt-10">
                        <div class="col-span-2">
                            <pricing-summary
                                :key="refresh"
                                :summary="pricingForm"
                            ></pricing-summary>
                        </div>
                        <div class="col-span-4">
                            <h2
                                class="text-2xl flex justify-start font-bold mb-1"
                            >
                                {{
                                    `${$t('pricing_params')} ${
                                        isDuplicate === true
                                            ? ' - (' + $t('copy_noun') + ')'
                                            : ''
                                    }`
                                }}
                            </h2>
                            <hr class="mb-3" />

                            <div
                                v-for="(
                                    pricingParam, index
                                ) in pricingForm.PricingParamClients"
                                :key="index"
                                class="grid grid-cols-12 gap-2 mb-2 items-center"
                            >
                                <div class="col-span-6">
                                    <Multiselect
                                        v-model="pricingParam.IdPricingParam"
                                        class="h-10 text-xs rounded px-3 py-2 mt-1 w-full darky"
                                        :options="paramsOptions"
                                        :hide-selected="true"
                                        label="name"
                                        track-by="name"
                                        :searchable="true"
                                        :placeholder="$t('select_items')"
                                        :allow-empty="false"
                                        :can-clear="false"
                                        @select="selectParam($event, index)"
                                    ></Multiselect>
                                    <!-- @search-change="
                                            searchParamsQuery($event)
                                        " -->
                                </div>
                                <div class="col-span-3">
                                    <input
                                        type="date"
                                        :min="
                                            new Date()
                                                .toISOString()
                                                .substr(0, 10)
                                        "
                                        :placeholder="$t('date_begin')"
                                        v-model="pricingParam.DateBegin"
                                        class="border rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.pricingForm
                                            .PricingParamClients.$each.$response
                                            .$errors[index].DateBegin"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-2">
                                    <input
                                        type="number"
                                        :placeholder="$t('price')"
                                        v-model="pricingParam.Value"
                                        step="0.01"
                                        min="0"
                                        class="border rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mb-2"
                                        v-for="error of v$.pricingForm
                                            .PricingParamClients.$each.$response
                                            .$errors[index].Value"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="flex justify-end">
                                        <fa-icon
                                            icon="trash-can"
                                            :title="$t('remove')"
                                            class="ml-2 cursor-pointer error-msg"
                                            @click.prevent="
                                                selectParam(
                                                    pricingParam,
                                                    index,
                                                    true,
                                                )
                                            "
                                        ></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <hr
                                v-if="
                                    pricingForm.PricingParamClients.length > 0
                                "
                                class="mt-3"
                            />

                            <div
                                v-if="
                                    pricingForm.PricingParamClients.length ===
                                        0 ||
                                    (pricingForm.PricingParamClients[
                                        pricingForm.PricingParamClients.length -
                                            1
                                    ].IdPricingParam !== '' &&
                                        pricingForm.PricingParamClients[
                                            pricingForm.PricingParamClients
                                                .length - 1
                                        ].Value !== 0 &&
                                        pricingForm.PricingParamClients[
                                            pricingForm.PricingParamClients
                                                .length - 1
                                        ].DateBegin !== '')
                                "
                                class="flex justify-center items-center my-2"
                            >
                                <button
                                    type="button"
                                    @click.prevent="addParam()"
                                    class="themed-button text-base ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <fa-icon
                                        icon="plus"
                                        class="fa-sm mr-2"
                                    ></fa-icon>

                                    {{ $t('add_param') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 4" #step4>
                <div class="grid grid-cols-6 gap-4 mt-10">
                    <div class="col-span-2">
                        <pricing-summary
                            :key="refresh"
                            :summary="pricingForm"
                        ></pricing-summary>
                    </div>
                    <div class="col-span-4">
                        <h2 class="text-2xl flex justify-start font-bold mb-1">
                            {{
                                `${$t('pricing_postal_groups')} ${
                                    isDuplicate === true
                                        ? ' - (' + $t('copy_noun') + ')'
                                        : ''
                                }`
                            }}
                        </h2>
                        <hr class="mb-3" />
                        <div
                            v-for="(
                                pricingPostalCodeGroup, index
                            ) in pricingForm.PricingPostalCodeGroupClients"
                            :key="index"
                            class="grid grid-cols-12 gap-2 mb-2 items-center"
                        >
                            <div class="col-span-6 flex items-center gap-2">
                                <Multiselect
                                    class="h-10 text-xs rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="
                                        pricingPostalCodeGroup.IdPricingPostalCodeGroupPickup
                                    "
                                    :options="groupsOptions"
                                    :hide-selected="true"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :placeholder="$t('pick_up_label')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    @select="setGroup($event, index)"
                                ></Multiselect>
                                <Multiselect
                                    class="h-10 text-xs rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="
                                        pricingPostalCodeGroup.IdPricingPostalCodeGroupDelivery
                                    "
                                    :options="groupsOptions"
                                    :hide-selected="true"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :placeholder="$t('delivery_label')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    @select="
                                        setGroup($event, index, 'delivery')
                                    "
                                ></Multiselect>
                            </div>
                            <div class="col-span-3">
                                <input
                                    type="date"
                                    :min="
                                        new Date().toISOString().substr(0, 10)
                                    "
                                    :placeholder="$t('date_begin')"
                                    v-model="pricingPostalCodeGroup.DateBegin"
                                    class="border rounded px-3 py-2 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.pricingForm
                                        .PricingPostalCodeGroupClients.$each
                                        .$response.$errors[index].DateBegin"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <input
                                    type="number"
                                    :placeholder="$t('price')"
                                    v-model="pricingPostalCodeGroup.Value"
                                    step="0.01"
                                    min="0"
                                    class="border rounded px-3 py-2 w-full"
                                />
                                <div
                                    class="text-xs italic mb-2"
                                    v-for="error of v$.pricingForm
                                        .PricingPostalCodeGroupClients.$each
                                        .$response.$errors[index].Value"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="flex justify-end">
                                    <fa-icon
                                        icon="trash-can"
                                        :title="$t('remove')"
                                        class="ml-2 cursor-pointer error-msg"
                                        @click.prevent="
                                            removeGroup(
                                                pricingPostalCodeGroup,
                                                index,
                                            )
                                        "
                                    ></fa-icon>
                                </div>
                            </div>
                        </div>
                        <hr
                            v-if="
                                pricingForm.PricingPostalCodeGroupClients
                                    .length > 0
                            "
                            class="mt-3"
                        />

                        <div
                            v-if="
                                pricingForm.PricingPostalCodeGroupClients
                                    .length === 0 ||
                                (pricingForm.PricingPostalCodeGroupClients[
                                    pricingForm.PricingPostalCodeGroupClients
                                        .length - 1
                                ].IdPricingParam !== '' &&
                                    pricingForm.PricingPostalCodeGroupClients[
                                        pricingForm
                                            .PricingPostalCodeGroupClients
                                            .length - 1
                                    ].Value !== 0 &&
                                    pricingForm.PricingPostalCodeGroupClients[
                                        pricingForm
                                            .PricingPostalCodeGroupClients
                                            .length - 1
                                    ].DateBegin !== '')
                            "
                            class="flex justify-center items-center my-2"
                        >
                            <button
                                type="button"
                                @click.prevent="addGroup()"
                                class="themed-button text-base ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                            >
                                <fa-icon
                                    icon="plus"
                                    class="fa-sm mr-2"
                                ></fa-icon>

                                {{ $t('add_postal_code_group') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </stepper>
    </div>
</template>

<script>
import Stepper from '@/components/atoms/Stepper.vue'
import clientMultiselectDebouncer from '@/mixins/clientMultiselectDebouncer'
import pricingMultiselectDebouncer from '@/mixins/pricingMultiselectDebouncer'
import { usePricingStore } from '@/stores/pricingStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import PricingSummary from './PricingSummary.vue'

export default {
    name: 'CreateOrUpdatePricing',
    mixins: [pricingMultiselectDebouncer, clientMultiselectDebouncer],
    components: {
        Stepper,
        Multiselect,
        PricingSummary,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            selectedClients: [],
            groupsOptions: [],
            noGroupError: false,
            pricingForm: {
                Id: '',
                Name: '',
                Active: true,
                DateBegin: new Date().toISOString().substr(0, 10),
                DateEnd: new Date().toISOString().substr(0, 10),
                Type: 'order',
                PostalCode: '',
                allClients: true,
                Clients: [],
                ClientsToRemove: [],
                PricingParamClientsToRemove: [],
                PricingPostalCodeGroupClientsToRemove: [],
                PricingParamClients: [],
                PricingPostalCodeGroupClients: [],
            },
            currentStep: 1,
        }
    },
    props: {
        pricingId: {
            type: Number,
            default: null,
        },

        isUpdate: {
            type: Boolean,
            default: false,
        },
        isDuplicate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
        ...mapWritableState(usePricingStore, { pricing: 'current' }),
    },

    created() {
        this.allClients = true
    },
    mounted() {
        this.searchPricingParams({
            query: [],
            filter: [],
            sortDirection: 'ASC',
            sortField: 'Title',
            perPage: 10000,
            page: 1,
        })
            .then((res) => {
                this.paramsOptions = []

                res.data.Records.forEach((param) => {
                    const option = {
                        value: param.Id,
                        name: param.Title,
                        Title: param.Title,
                        Id: param.Id,
                    }
                    this.paramsOptions.push(option)
                })
            })
            .catch((err) => {
                this.noParamError = true
            })
        this.searchGroups({
            query: [],
            filter: [],
            sortDirection: 'ASC',
            sortField: 'Name',
            perPage: 10000,
            page: 1,
        })
            .then((res) => {
                this.groupsOptions = []

                res.data.Records.forEach((group) => {
                    const option = {
                        value: group.Id,
                        name: group.Name,
                        Name: group.Name,
                        Id: group.Id,
                    }
                    this.groupsOptions.push(option)
                })
            })
            .catch((err) => {
                this.noGroupError = true
            })
        if (
            (this.isUpdate === true || this.isDuplicate === true) &&
            this.pricingId
        ) {
            this.fetchOnePricing(this.pricingId).then((res) => {
                this.currentStep = 1

                this.pricingForm = structuredClone(res.data)
                this.pricingForm.Active = +this.pricingForm.Active === 1
                this.pricingForm.ClientsToRemove = []
                this.pricingForm.PricingParamClientsToRemove = []
                this.pricingForm.PricingPostalCodeGroupClientsToRemove = []
                this.selectedClients = []
                const pricingOption = {
                    value: this.pricingForm.Id,
                    name: this.pricingForm.Name,
                    Name: this.pricingForm.Name,
                    Id: this.pricingForm.Id,
                    Type: this.pricingForm.Type,
                    Active: this.pricingForm.Active,
                    DateBegin: this.pricingForm.DateBegin,
                    DateEnd: this.pricingForm.DateEnd,
                    Clients: this.pricingForm.Clients,
                    PricingParamClients:
                        this.pricingForm.PricingParamClients ?? [],
                    PricingPostalCodeGroupClients:
                        this.pricingForm.PricingPostalCodeGroupClients ?? [],
                }
                this.pricingOptions.push(pricingOption)
                if (this.pricingForm.Clients.length > 0) {
                    this.allClients = false
                    this.pricingForm.Clients.forEach((client) => {
                        const selectedClient = {
                            Id: client.Id,
                            Name: client.Name,
                            DefaultPricing: client.DefaultPricing,
                        }
                        this.selectedClients.push(selectedClient)
                    })
                }

                if (!this.pricingForm.PricingParamClients) {
                    this.pricingForm.PricingParamClients = []
                }
                if (this.pricingForm.PricingParamClients.length > 0) {
                    this.pricingForm.PricingParamClients.forEach((param) => {
                        param.Title = param.PricingParam.Title
                    })
                }
                if (!this.pricingForm.PricingPostalCodeGroupClients) {
                    this.pricingForm.PricingPostalCodeGroupClients = []
                }
                if (this.isDuplicate === true) {
                    this.pricingForm.Id = ''
                    this.pricingForm.Name = `${this.pricingForm.Name} - ${this.$t(
                        'copy_noun',
                    )}`
                }
                this.refresh += 1
            })
        }
    },
    validations() {
        return {
            pricingForm: {
                Name: {
                    required,
                },

                Type: {
                    required,
                },
                DateBegin: {
                    required,
                },
                DateEnd: {
                    required,
                },
                PricingParamClients: {
                    $each: helpers.forEach({
                        IdPricingParam: {
                            required,
                        },
                        Value: {
                            required,
                        },
                        DateBegin: {
                            required,
                        },
                    }),
                },
                PricingPostalCodeGroupClients: {
                    $each: helpers.forEach({
                        IdPricingPostalCodeGroupPickup: {
                            required,
                        },
                        IdPricingPostalCodeGroupDelivery: {
                            required,
                        },
                        Value: {
                            required,
                        },
                        DateBegin: {
                            required,
                        },
                    }),
                },
            },
        }
    },
    methods: {
        ...mapActions(usePricingStore, [
            'createPricing',
            'updatePricing',
            'fetchOnePricing',
            'searchPricingParams',
            'searchGroups',
        ]),

        resetForm() {
            this.pricingForm = {
                Id: '',
                Name: '',
                Active: true,
                DateBegin: new Date().toISOString().substr(0, 10),
                DateEnd: new Date().toISOString().substr(0, 10),
                Type: 'order',
                PostalCode: '',
                Clients: [],
                PricingParamClients: [],
                PricingPostalCodeGroupClients: [],
                ClientsToRemove: [],
                PricingParamClientsToRemove: [],
                PricingPostalCodeGroupClientsToRemove: [],
            }

            this.currentStep = 1
            this.$emit('cancel')
        },

        setPricing(pricingId = null) {
            if (pricingId === null) {
                this.pricingForm = {
                    Id: '',
                    Name: '',
                    Active: true,
                    DateBegin: new Date().toISOString().substr(0, 10),
                    DateEnd: new Date().toISOString().substr(0, 10),
                    Type: 'order',
                    PostalCode: '',
                    Clients: [],
                    DefaultPricing: false,
                    ClientsToRemove: [],
                    PricingParamClientsToRemove: [],
                    PricingPostalCodeGroupClientsToRemove: [],
                    PricingParamClients: [],
                    PricingPostalCodeGroupClients: [],
                }

                this.currentStep = 1
                return
            }
            const pricing = this.pricingOptions.find(
                (pr) => pr.Id === pricingId,
            )
            this.pricingForm = pricing
        },
        selectClient(client, index = null) {
            if (client.value === 0) {
                this.allClients = true
                this.selectedClients = []
                this.pricingForm.Clients.forEach((clToRem) => {
                    this.pricingForm.ClientsToRemove.push({ Id: clToRem.Id })
                })
            } else if (index === null) {
                this.allClients = false
                const selectedClient = {
                    Id: client.value,
                    Name: client.name,
                    DefaultPricing: false,
                }
                this.selectedClients.push(selectedClient)
            } else {
                this.selectedClients.splice(index, 1)
                if (client.Id > 0) {
                    this.pricingForm.ClientsToRemove.push({ Id: client.Id })
                }
                if (this.selectedClients.length === 0) {
                    this.allClients = true
                } else {
                    this.allClients = false
                }
            }
            this.pricingForm.Clients = this.selectedClients
        },
        addParam() {
            this.pricingForm.PricingParamClients.push({
                Id: '',
                IdPricingParam: '',
                Title: '',
                Value: 0,
                DateBegin: new Date().toISOString().substr(0, 10),
            })
        },

        selectParam(param, index = null, remove = false) {
            // TODO ne fonctionne pas comme pour les clients tableau normal
            const pricingParam = this.paramsOptions.find(
                (pr) => pr.value === param,
            )
            if (remove === false) {
                const selectedParam = {
                    IdPricingParam: pricingParam.value,
                    Title: pricingParam.name,
                    DateBegin: new Date().toISOString().substr(0, 10),
                    Id: '',
                    Value: 0,
                }
                this.pricingForm.PricingParamClients[index] = selectedParam
            } else {
                this.pricingForm.PricingParamClients.splice(index, 1)
                if (param.Id > 0 && param.Id !== '') {
                    this.pricingForm.PricingParamClientsToRemove.push({
                        Id: param.Id,
                    })
                }
            }
            // this.pricingForm.PricingParamClients = this.selectedParams
        },
        removeGroup(group, index) {
            this.pricingForm.PricingPostalCodeGroupClients.splice(index, 1)
            if (group.Id > 0) {
                this.pricingForm.PricingPostalCodeGroupClientsToRemove.push({
                    Id: group.Id,
                })
            }
        },
        addGroup() {
            this.pricingForm.PricingPostalCodeGroupClients.push({
                Id: '',
                IdPricingPostalCodeGroupPickup: '',
                IdPricingPostalCodeGroupDelivery: '',
                PricingPostalCodeGroupPickup: null,
                PricingPostalCodeGroupPDelivery: null,
                DateBegin: new Date().toISOString().substr(0, 10),
                Value: 0,
            })
        },
        setGroup(group, index, type = 'pickup') {
            const pricingGroup = this.groupsOptions.find(
                (pr) => pr.value === group,
            )
            if (type === 'pickup') {
                this.pricingForm.PricingPostalCodeGroupClients[
                    index
                ].IdPricingPostalCodeGroupPicku = pricingGroup.value
                this.pricingForm.PricingPostalCodeGroupClients[
                    index
                ].PricingPostalCodeGroupPickup = pricingGroup
            } else {
                this.pricingForm.PricingPostalCodeGroupClients[
                    index
                ].IdPricingPostalCodeGroupDelivery = pricingGroup.value
                this.pricingForm.PricingPostalCodeGroupClients[
                    index
                ].PricingPostalCodeGroupDelivery = pricingGroup
            }
        },
        async savePricing() {
            this.v$.pricingForm.$reset()
            const isValid = await this.v$.pricingForm.$validate()
            if (!isValid) {
                this.$toast.error(this.$t('form_errors'))
                return
            }
            if (this.isUpdate === false) {
                this.createPricing(this.pricingForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_created'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                this.updatePricing(this.pricingForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_updated'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            }
        },
    },
    unmounted() {
        this.pricing = null
    },
}
</script>

<style scoped>
/* Styles Tailwind déjà intégrés dans les classes */
</style>
