import { usePricingStore } from '@/stores/pricingStore'
import { mapActions } from 'pinia'

export default {
    name: 'multiselectDebouncerPricing',
    data() {
        return {
            debouncePricing: null,
            pricingSearchQuery: '',
            pricingOptions: [],
            noPricingError: false,
            debounceParams: null,
            paramsSearchQuery: '',
            paramsOptions: [],
            noParamError: false,
        }
    },
    methods: {
        ...mapActions(usePricingStore, [
            'searchPricings',
            'searchClientPricingZipGroups',
            'searchPricingParams',
        ]),
        searchPricingsQuery(query) {
            this.noPricingError = false
            this.pricingSearchQuery = query
            if (query !== '') {
                clearTimeout(this.debouncePricing)
                const filter = []

                this.debouncePricing = setTimeout(() => {
                    this.searchPricings({
                        query: [
                            { dbField: 'Name', term: this.pricingSearchQuery },
                        ],
                        filter,
                        sortDirection: 'ASC',
                        sortField: 'Name',
                        perPage: 10000,
                        page: 1,
                    })
                        .then((res) => {
                            this.pricingOptions = []

                            res.data.Records.forEach((pricing) => {
                                const option = {
                                    value: pricing.Id,
                                    name: pricing.Name,
                                    Name: pricing.Name,
                                    Id: pricing.Id,
                                    Type: pricing.Type,
                                    Active: +pricing.Active === 1,
                                    DateBegin: pricing.DateBegin,
                                    DateEnd: pricing.DateEnd,
                                    Clients: pricing.Clients,
                                }
                                this.pricingOptions.push(option)
                            })
                        })
                        .catch((err) => {
                            this.noPricingError = true
                        })
                }, 200)
            }
        },
        searchParamsQuery(query) {
            this.noParamError = false
            this.paramsSearchQuery = query
            if (query !== '') {
                clearTimeout(this.debounceParams)
                const filter = []

                this.debounceParams = setTimeout(() => {
                    this.searchPricingParams({
                        query: [
                            { dbField: 'Title', term: this.paramsSearchQuery },
                        ],
                        filter,
                        sortDirection: 'ASC',
                        sortField: 'Title',
                        perPage: 10000,
                        page: 1,
                    })
                        .then((res) => {
                            this.paramsOptions = []

                            res.data.Records.forEach((param) => {
                                const option = {
                                    value: param.Id,
                                    name: param.Title,
                                    Title: param.Title,
                                    Id: param.Id,
                                }
                                this.paramsOptions.push(option)
                            })
                        })
                        .catch((err) => {
                            this.noParamError = true
                        })
                }, 200)
            }
        },
    },
}
