<template>
    <div class="grid gap-4" :class="`grid-cols-${colsForGrid}`">
        <div
            v-for="(count, index) in counts"
            :key="index"
            class="rounded-md border-2 col-span-2 h-32 hover:border-4"
            :class="count.subtext ? 'py-2 px-2' : 'py-10 px-6'"
        >
            <div
                class="flex items-center cursor-pointer"
                @click.prevent="toList(count)"
            >
                <span
                    v-if="count.icon.layer"
                    class="rounded-md py-2 px-3 w-fit light"
                    :class="count.bgColor"
                >
                    <span :id="`icon-${count.name}`" :class="count.color">
                        <fa-layer>
                            <fa-icon
                                :icon="count.icon.iconA"
                                :class="count.color"
                            ></fa-icon>

                            <fa-icon
                                class="fa-sm"
                                style="font-weight: 200"
                                transform="shrink-6 up-10 right-14"
                                :icon="count.icon.iconB"
                                :class="count.color"
                            ></fa-icon>
                            <!-- transform="shrink-6" -->
                        </fa-layer>
                    </span>
                </span>
                <span
                    v-else-if="count.icon.stack"
                    class="rounded-md py-1 px-2 pt-2 w-fit light"
                    :class="count.bgColor"
                >
                    <span :id="`icon-${count.name}`" :class="count.color">
                        <fa-layer class="fa-xl">
                            <fa-icon
                                :icon="count.icon.iconA"
                                class="text-red-500"
                                :class="count.color"
                            ></fa-icon>

                            <fa-icon
                                transform="shrink-6 "
                                :icon="count.icon.iconB"
                                :class="count.color"
                            ></fa-icon>
                            <!-- transform="shrink-6" -->
                        </fa-layer>
                    </span>
                </span>
                <span
                    v-else
                    class="rounded-md py-2 px-3 w-fit light"
                    :class="count.bgColor"
                >
                    <fa-icon
                        class="fa-lg"
                        :class="count.color"
                        :icon="count.icon"
                    />
                </span>
                <span
                    class="text-3xl font-semibold mx-8"
                    :class="
                        coloredText === true && count.color !== 'text-black'
                            ? count.color
                            : ''
                    "
                    >{{ count.value }}</span
                >

                <span
                    class="text-sm"
                    :class="
                        coloredText === true && count.color !== 'text-black'
                            ? count.color
                            : ''
                    "
                    >{{ $t(count.name, +count.value <= 1 ? 1 : 2) }}</span
                >
            </div>
            <div v-if="count.subtext" class="text-xs mt-2 italic">
                {{ $t(count.subtext, +count.value <= 1 ? 1 : 2) }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DashboardCards',

    props: {
        counts: {
            type: Array,
            default: () => [],
            required: true,
        },
        coloredText: {
            type: Boolean,
            default: false,
        },
        colsForGrid: {
            type: String,
            default: '8',
        },
    },
    methods: {
        toList(el) {
            const search = {
                query: [],
                sortDirection: 'DESC',
                sortField: 'CreationDate',
                filter: el.filterForSearch,
                page: 1,
                perPage: 50,
            }
            if (el.cookieName && el.cookieName !== '') {
                if (el.dateFilter && el.dateFilter.length > 0) {
                    search.dateFilter = el.dateFilter
                }
                this.$cookies.remove(el.cookieName)
                this.$cookies.set('fromDashboard', true)
                this.$cookies.set(el.cookieName, search)
            }
            this.$router.push({ name: el.redirectTo })
        },
    },
}
</script>
