<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @goToUpdateArea="toUpdate($event)"
        />
    </div>
</template>
<script>
import { mapStores } from 'pinia'
import NavTabs from '@/components/elements/NavTabs.vue'
import Areas from '@/views/areas/List.vue'
import NewArea from '@/views/areas/CreateOrUpdate.vue'
import { useAreaStore } from '@/stores/areaStore'

export default {
    name: 'AreasWrapper',
    components: {
        Areas,
        NavTabs,
        NewArea,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name,
            ),
            tabs: [['list-ul', 'areas']],
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
            this.$cookies.remove('filtered')
        }
    },
    mounted() {
        if (this.$route.name === 'update_area') {
            this.toUpdate(this.$route.params.id)
        } else {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            if (this.sideBarNav === 'update_area') {
                this.toUpdate(this.$route.params.id)
            } else {
                this.changeContent(this.$route.name)
            }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapStores(useAreaStore),
    },
    methods: {
        toCreate() {
            this.areaStore.$patch({ current: null })

            this.componentToDisplay = this.humanize('new_area')
            this.$router.push({ name: 'new_area' })
        },
        toUpdate(id) {
            this.areaStore.$patch({ current: null })

            this.areaStore.fetchOne(id).then(() => {
                this.$router.push({ name: 'update_area', params: { id } })
                this.componentToDisplay = this.humanize('new_area')
            })
        },
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
