<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @goToUpdateClient="toUpdate($event)"
            @goToUpdateAddress="toUpdateAddress($event)"
            @createAddress="toCreateAddress($event)"
            @createContact="toAddContact($event)"
            @contacts="toClientContacts($event)"
            @addContact="toAddContact($event)"
            @updateContact="toUpdateContact($event)"
            @clientDeliveryAdresses="toClientDeliveryAddresses($event)"
        />
    </div>
</template>
<script>
import NavTabs from '@/components/elements/NavTabs.vue'
import NewClient from '@/views/clients/CreateOrUpdate.vue'
import NewAddress from '@/views/clients/CreateOrUpdateAddress.vue'
import NewContact from '@/views/clients/CreateOrUpdateContact.vue'
import Clients from '@/views/clients/List.vue'
import { mapActions, mapStores, mapWritableState } from 'pinia'

import { useClientStore } from '@/stores/clientStore'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useUserStore } from '@/stores/userStore'
import DeliveryAddresses from './AddressesList.vue'
import Contacts from './ClientContactsList.vue'
import ContactClientHistory from './ContactClientHistoryList.vue'
import Contracts from './ContractsList.vue'

export default {
    name: 'ClientsWrapper',
    components: {
        NavTabs,
        Clients,
        Contacts,
        DeliveryAddresses,
        ContactClientHistory,
        NewContact,
        NewClient,
        NewAddress,
        Contracts
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'clients'],
                ['address-book', 'delivery_addresses', true],
            ],
        }
    },
    computed: {
        ...mapStores(useClientStore),
        ...mapWritableState(useContactClientHistoryStore, { history: 'all' }),
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filtered')
        this.$cookies.remove('filtered_addresses')
        this.$cookies.remove('filtered_clients_contacts')
        this.$cookies.remove('filtered_contactClientHistory')
        this.$cookies.remove('filteredContractsList')
        }

    },
    mounted() {
        if (this.$route.name === 'new_contact') {
            this.toAddContact(this.$route.params.client_id)
        } else if (!this.$route.name === 'new_address') {
            this.toCreateAddress(this.$route.params.client_id)
        } else if (this.$route.name === 'update_client') {
            this.toUpdate(this.$route.params.id)
        } else if (this.$route.name === 'update_address') {
            this.toUpdateAddress(this.$route.params.id)
        } else if (this.$route.name === 'contacts') {
            this.toClientContacts(this.$route.params.client_id)
        } else if (this.$route.name === 'update_contact') {
            this.toUpdateContact(this.$route.params.id)
        } else if (
            this.$route.name === 'client_delivery_addresses' &&
            this.$route.params.id
        ) {
            this.toClientDeliveryAddresses(this.$route.params.id)
        } else if (!this.$route.params.id && !this.$route.params.client_id) {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
                this.$cookies.remove('filtered_addresses')
                this.$cookies.remove('filtered_clients_contacts')
                this.$cookies.remove('filtered_contactClientHistory')
                this.$cookies.remove('filteredContractsList')
            }

            if (this.sideBarNav === 'new_contact') {
                this.toAddContact(this.$route.params.client_id)
            } else if (!this.sideBarNav === 'new_address') {
                this.toCreateAddress(this.$route.params.client_id)
            } else if (this.sideBarNav === 'update_client') {
                this.toUpdate(this.$route.params.id)
            } else if (this.sideBarNav === 'update_address') {
                this.toUpdateAddress(this.$route.params.id)
            } else if (this.sideBarNav === 'contacts') {
                this.toClientContacts(this.$route.params.client_id)
            } else if (this.sideBarNav === 'update_contact') {
                this.toUpdateContact(this.$route.params.id)
            } else if (
                this.sideBarNav === 'client_delivery_addresses' &&
                this.$route.params.id
            ) {
                this.toClientDeliveryAddresses(this.$route.params.id)
            } else if (
                !this.$route.params.id &&
                !this.$route.params.client_id
            ) {
                this.changeContent(this.sideBarNav)
            }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        ...mapActions(useUserStore, { fetchOneUser: 'fetchOne' }),
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        toCreate() {
            this.history = null
            this.componentToDisplay = this.humanize('new_client')
            this.$router.push({ name: 'new_client' })
        },
        toUpdate(id) {
            this.clientStore.fetchOne(id).then(() => {
                this.componentToDisplay = this.humanize('new_client')
                this.$router.push({ name: 'update_client', params: { id } })
            })
            // this.componentToDisplay = this.humanize('new_client')
            // this.$router.push({ name: 'update_client', params: { id } })
        },
        toCreateAddress(client) {
            if (client) {
                this.clientStore.fetchOne(client)
                // then(() => {
                this.$router.push({
                    name: 'new_address_for',
                    params: { client },
                })
                this.componentToDisplay = this.humanize('new_address')
                // })
            } else {
                const clientsPayload = {
                    query: [],
                    filter: [],
                    perPage: 100000000000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }
                this.clientStore.search(clientsPayload).then(() => {
                    this.$router.push({ name: 'new_address' })
                    this.componentToDisplay = this.humanize('new_address')
                })
            }
        },
        async toUpdateAddress(id) {
            if (this.$route.params.client) {
                this.clientStore
                    .fetchOne(this.$route.params.client)
                    .then(() => {
                        this.clientStore
                            .fetchOneAddress({
                                id,
                                full: true,
                            })
                            .then(() => {
                                this.componentToDisplay =
                                    this.humanize('new_address')
                                this.$router.push({
                                    name: 'update_address',
                                    params: { id },
                                })
                            })
                    })
            } else {
                this.clientStore
                    .fetchOneAddress({
                        id,
                        full: true,
                    })
                    .then(() => {
                        this.componentToDisplay = this.humanize('new_address')
                        this.$router.push({
                            name: 'update_address',
                            params: { id },
                        })
                    })
            }
        },
        toClientContacts(id) {
            this.clientStore.fetchOne(id).then(() => {
                this.componentToDisplay = this.humanize('contacts')

                this.$router.push({
                    name: 'contacts',
                    params: { client_id: id },
                })
            })
        },
        toAddContact(clientId) {
            this.$router.push({
                name: 'new_contact',
                params: { client_id: clientId },
            })
            this.clientStore.fetchOne(clientId).then(() => {
                this.componentToDisplay = this.humanize('new_contact')
            })
        },
        toUpdateContact(id) {
            // ? id = contact Id = userId
            if (id.client) {
                this.$router.push({
                    name: 'update_contact',
                    params: { id: id.contact, client_id: id.client },
                })
                this.fetchOneUser(id.contact).then(() => {
                    this.componentToDisplay = this.humanize('new_contact')
                })
            } else {
                this.$router.push({
                    name: 'update_contact',
                    params: { id },
                })
                this.fetchOneUser(id).then(() => {
                    this.componentToDisplay = this.humanize('new_contact')
                })
            }
        },
        toClientDeliveryAddresses(id) {
            this.componentToDisplay = this.humanize('delivery_addresses')
            this.$router.push({
                name: 'client_delivery_addresses',
                params: { id },
            })
            this.clientStore.fetchOne(id)

            this.clientStore.fetchAddresses({ clientId: id })
        },

        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
        getPosition() {
            const bounding = document
                .getElementById('navtabs')
                .getBoundingClientRect()
            return bounding
        },
    },
}
</script>
