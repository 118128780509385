<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'pricing_postal_groups'"
            />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="clientzipGroupsList"
                :items="clientGroupsZip"
                :columns="columns"
                :storeToSort="'clientGroupsZip'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :headerText="
                isUpdate
                    ? 'update_client_postal_code_group'
                    : 'add_client_postal_code_group'
            "
            @sendFromModal="addGroup()"
            @closeModal="openModal(null)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center italic my-4">
                        {{ $t('group_modal_info') }}
                    </div>
                    <div class="grid grid-cols-6 gap-2 items-center my-2">
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 mb-2 pb-1 mr-2"
                            >
                                {{ $t('client') }}
                            </div>

                            <Multiselect
                                id="clientMS"
                                class="darky"
                                :key="refreshMs"
                                v-model="clientGroupsZipForm.IdClient"
                                :options="clientOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_client_info')"
                                :allow-empty="true"
                                :can-clear="true"
                                @search-change="searchClientsQuery($event)"
                            ></Multiselect>
                        </div>
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 mb-2 pb-1 mr-2"
                            >
                                {{ $t('pricing') }}
                            </div>

                            <Multiselect
                                id="PricingMS"
                                class="darky"
                                :key="refreshMs"
                                v-model="clientGroupsZipForm.IdPricing"
                                :options="pricingOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_one')"
                                :allow-empty="true"
                                :can-clear="true"
                            ></Multiselect>
                        </div>
                    </div>
                    <div class="grid grid-cols-6 gap-2 items-center my-4">
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >
                                {{ $t('pickup_zip_group') + ' *' }}
                            </div>
                            <Multiselect
                                id="pickupMs"
                                class="darky"
                                :key="refreshMs"
                                v-model="
                                    clientGroupsZipForm.IdPricingPostalCodeGroupPickup
                                "
                                :options="postalCodesOptions"
                                label="name"
                                track-by="name"
                                :placeholder="$t('select_one')"
                                :searchable="true"
                                :allow-empty="false"
                                :can-clear="false"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientGroupsZipForm
                                    .IdPricingPostalCodeGroupPickup.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >
                                {{ $t('delivery_zip_group') + ' *' }}
                            </div>
                            <Multiselect
                                id="delivMs"
                                class="darky"
                                :key="refreshMs"
                                v-model="
                                    clientGroupsZipForm.IdPricingPostalCodeGroupDelivery
                                "
                                :options="postalCodesOptions"
                                label="name"
                                track-by="name"
                                :placeholder="$t('select_one')"
                                :searchable="true"
                                :allow-empty="false"
                                :can-clear="false"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientGroupsZipForm
                                    .IdPricingPostalCodeGroupDelivery.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-6 gap-2 items-center my-4">
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >
                                {{ $t('value_per_km') + ' *' }}
                            </div>
                            <input
                                type="number"
                                v-model="clientGroupsZipForm.Value"
                                step="0.01"
                                min="0"
                                class="border rounded px-3 py-2 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientGroupsZipForm.Value
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="justify-center col-span-3">
                            <div
                                class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >
                                {{ $t('date_begin') + ' *' }}
                            </div>
                            <input
                                type="date"
                                :min="new Date().toISOString().substr(0, 10)"
                                v-model="clientGroupsZipForm.DateBegin"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />

                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientGroupsZipForm.DateBegin
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template></modal
        >

        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.title" class="mr-1">
                                    {{ `${toConfirm.title} ` }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { usePricingStore } from '@/stores/pricingStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapStores, mapWritableState } from 'pinia'
import clientMultiselectDebouncer from '../../../mixins/clientMultiselectDebouncer'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'groupsList',
    mixins: [filterList, clientMultiselectDebouncer],
    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            refreshMs: 0,
            searchQuery: '',
            isUpdate: false,
            show: false,
            entityTypeToDelete: 'postalcode_groups_tc',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            clientGroupsZipInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            newFromParam: false,
            clientGroupsZipForm: {
                Id: '',
                IdPricingPostalCodeGroupPickup: '',
                IdPricingPostalCodeGroupDelivery: '',
                IdClient: 0,
                IdPricing: 0,
                Value: 0,
                DateBegin: new Date().toISOString().substr(0, 10),
            },
            postalCodesOptions: [],
            pricingOptions: [],
        }
    },
    watch: {
        clientGroupsZip() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    validations() {
        return {
            clientGroupsZipForm: {
                IdPricingPostalCodeGroupPickup: { required },
                IdPricingPostalCodeGroupDelivery: { required },
                Value: { required },
                DateBegin: { required },
            },
        }
    },
    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredClientPricingZipGroups')
        this.columns = [
            {
                name: 'client',
                filtrable: true,
                dbField: 'Client.Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.clientGroupsZipActionAccess.update,
                ),
            },
            {
                name: 'pricing',
                filtrable: true,
                dbField: 'Pricing.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'date_begin',
                filtrable: false,
                dbField: 'DateBegin',
            },
            {
                name: 'pickup_zip_group',
                filtrable: true,
                dbField: 'PricingPostalCodeGroup.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'delivery_zip_group',
                filtrable: true,
                dbField: 'PricingPostalCodeGroup.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'value',
                filtrable: false,
                dbField: 'Value',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.clientGroupsZipInterval)
    },
    computed: {
        clientGroupsZipActionAccess() {
            return actionsAccess.pricings.clientGroupsZip
        },
        ...mapWritableState(usePricingStore, {
            clientGroupsZip: 'clientGroupsZip',
            count: 'clientGroupsZipCount',
            fetchError: 'fetchError',
            totalPages: 'clientGroupsZipTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.clientGroupsZipActionAccess.update,
                )
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            // TODO add this action when the dock is created + ADD AUTH

            // if (
            //     checkAccess(
            //         this.userStore,
            //         this.clientGroupsZipActionAccess.delete,
            //     )
            // ) {
            //     actions.push({
            //         name: 'delete',
            //         action: 'delete',
            //         icon: 'trash-can',
            //     })
            // }

            return actions
        },
        multiActions() {
            const actions = []
            // if (
            //     checkAccess(
            //         this.userStore,
            //         this.clientGroupsZipActionAccess.delete,
            //     )
            // ) {
            //     actions.push({
            //         name: 'delete_selection',
            //         action: 'deleteMany',
            //         icon: 'trash-can',
            //     })
            // }
            return actions
        },
    },
    methods: {
        ...mapActions(usePricingStore, [
            'searchClientPricingZipGroups',
            'createClientPricingZipGroup',
            'updateClientPricingZipGroup',
            'deleteClientPricingZipGroup',
            'deleteManyClientPricingZipGroups',
            'searchPricings',
            'searchGroups',
        ]),
        upFilter(event) {
            clearInterval(this.clientGroupsZipInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.clientGroupsZipActionAccess.create,
            )
        },
        openModal(id = null) {
            clearInterval(this.clientGroupsZipInterval)
            this.isUpdate = false
            this.pricingOptions = []
            this.postalCodesOptions = []
            this.clientOptions = []
            this.allClients = true
            this.searchClientsQuery('')
            const payload = {
                query: '',
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Name',
                sortDirection: this.sortDirection,
            }
            this.searchPricings(payload, false).then((res) => {
                // this.pricingOptions.push({
                //     name: this.$t('all'),
                //     value: 0,
                // })
                res.data.Records.forEach((pricing) => {
                    this.pricingOptions.push({
                        name: pricing.Name,
                        value: pricing.Id,
                    })
                })
                this.refreshMs += 1
            })
            this.searchGroups(payload, false).then((res) => {
                this.postalCodesOptions.push({
                    name: this.$t('none'),
                    value: 0,
                })
                res.data.Records.forEach((pricing) => {
                    this.postalCodesOptions.push({
                        name: pricing.Name,
                        value: pricing.Id,
                    })
                })
                this.refreshMs += 1
            })

            if (id !== null) {
                const groupToUpdate = this.clientGroupsZip.find(
                    (p) => p.id === id,
                )
                this.searchClientsQuery(groupToUpdate.client)
                this.clientGroupsZipForm = {
                    Id: groupToUpdate.id,
                    IdPricingPostalCodeGroupPickup: groupToUpdate.id_pickUpZip,
                    IdPricingPostalCodeGroupDelivery:
                        groupToUpdate.id_deliveryZip,
                    IdClient: groupToUpdate.id_client,
                    IdPricing: groupToUpdate.id_pricing,
                    Value: groupToUpdate.value,
                    DateBegin: groupToUpdate.date_begin,
                }

                this.isUpdate = true
            } else {
                this.clientGroupsZipForm = {
                    Id: '',
                    IdPricingPostalCodeGroupPickup: '',
                    IdPricingPostalCodeGroupDelivery: '',
                    IdClient: 0,
                    IdPricing: 0,
                    Value: 0,
                    DateBegin: new Date().toISOString().substr(0, 10),
                }
            }
            this.show = !this.show
            this.refreshMs += 1
        },
        async addGroup() {
            this.v$.clientGroupsZipForm.$reset()

            const isValid = await this.v$.clientGroupsZipForm.$validate()
            if (isValid === true) {
                if (this.isUpdate) {
                    this.updateClientPricingZipGroup(this.clientGroupsZipForm)
                        .then((res) => {
                            this.$toast.success(this.$t('update_ok'))
                            this.setResults()
                            this.openModal(null)
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('update_ko'))
                        })
                } else {
                    this.createClientPricingZipGroup(this.clientGroupsZipForm)
                        .then(() => {
                            this.$toast.success(this.$t('create_ok'))
                            this.openModal(null)
                            this.setResults()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('create_ko'))
                        })
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.clientGroupsZipInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.entityTypeToDelete = 'postalcode_groups_tc'

            const group = this.clientGroupsZip.find((h) => h.id === id)
            group.title = `${group.client} - ${group.pricing} - ${group.id_pickUpZip} ${this.$t('to')} ${group.id_deliveryZip}`

            this.elementsToConfirm.push(group)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteClientPricingZipGroup(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.clientGroupsZipInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'postalcode_groups_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const group = this.clientGroupsZip.find((h) => h.id === id)
                group.title = `${group.client} - ${group.pricing} - ${group.id_pickUpZip} ${this.$t('to')} ${group.id_deliveryZip}`
                this.elementsToConfirm.push(group)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyClientPricingZipGroups(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.clientGroupsZipInterval)
            this.$cookies.remove('filteredClientPricingZipGroups')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.clientGroupsZipInterval)
            this.$cookies.remove('filteredClientPricingZipGroups')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchClientPricingZipGroups(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredClientPricingZipGroups', search)
            this.clientGroupsZipInterval = setInterval(() => {
                this.searchClientPricingZipGroups(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.clientGroupsZipInterval)
            this.$cookies.remove('filteredClientPricingZipGroups')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
