<template>
    <div
        class="bg-gainsboro-200 flex flex-col items-start justify-start tracking-[normal] h-full"
    >
        <section
            class="self-stretch bg-whitesmoke flex flex-col items-start justify-start py-5 px-[20px] overflow-y-auto gap-[30px] text-left text-darkslategray-100 font-circular-std"
        >
            <div
                class="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap"
            >
                <div class="flex flex-col items-start justify-end gap-[5px]">
                    <h2 class="m-0 relative text-xl font-bold">
                        {{ $t('summary') }}
                    </h2>
                    <div class="mt-2 font-semibold">
                        {{ summary.Name }}
                    </div>

                    <div class="mt-2">
                        {{
                            $t('type') +
                            ' : ' +
                            (summary.Type === 'order'
                                ? $t('order_price_param')
                                : $t('order_group'))
                        }}
                    </div>
                    <div class="mt-2">
                        {{
                            summary.Active === true || summary.Active === 1
                                ? $t('active')
                                : $t('deactivated')
                        }}
                    </div>
                    <div class="mt-2">
                        {{
                            $t('from') +
                            ' ' +
                            summary.DateBegin +
                            ' ' +
                            $t('to') +
                            ' ' +
                            summary.DateEnd
                        }}
                    </div>
                    <div v-if="summary.Clients && summary.Clients.length > 0">
                        <div
                            class="flex flex-col mt-2 items-start justify-end gap-[5px]"
                        >
                            <div class="mt-2 font-semibold">
                                {{
                                    $t(
                                        'selected_clients',
                                        summary.Clients.length === 1 ? 1 : 2,
                                    )
                                }}
                            </div>
                            <div class="grid grid-cols-3 gap-1">
                                <div
                                    v-for="(client, index) in summary.Clients"
                                    :key="index"
                                    class="col-span-1 border-2 rounded p-2 w-full overflow-y-auto h-full"
                                >
                                    <div class="text-sm">
                                        {{ `${client.Name}  ` }}
                                    </div>
                                    <div class="text-xs italic">
                                        {{
                                            `${client.DefaultPricing === true ? $t('default') : ''}`
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="italic mt-3 flex justify-center items-center theme-color"
                        >
                            {{ $t('all_clients_selected') }}
                        </div>
                    </div>
                    <div
                        v-if="
                            summary.PricingParamClients &&
                            summary.PricingParamClients.length > 0 &&
                            summary.PricingParamClients[0].Title !== ''
                        "
                    >
                        <div
                            class="flex flex-col mt-3 items-start justify-end gap-[5px]"
                        >
                            <div class="mt-2 font-semibold">
                                {{
                                    $t(
                                        'selected_params',
                                        summary.PricingParamClients.length === 1
                                            ? 1
                                            : 2,
                                    )
                                }}
                            </div>
                            <div class="grid grid-cols-2 gap-2">
                                <div
                                    v-for="(
                                        param, index
                                    ) in summary.PricingParamClients"
                                    :key="index"
                                >
                                    <div
                                        class="col-span-1 text-sm border-2 rounded p-2 w-full h-full overflow-y-auto"
                                    >
                                        {{
                                            `${param.DateBegin} ⋅ ${param.Title} ⋅ ${param.Value} €`
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="italic mt-3 flex justify-center items-center theme-color"
                        >
                            {{ $t('no_params_selected') }}
                        </div>
                    </div>

                    <div
                        v-if="
                            summary.PricingPostalCodeGroupClients &&
                            summary.PricingPostalCodeGroupClients.length > 0 &&
                            summary.PricingPostalCodeGroupClients[0]
                                .PricingPostalCodeGroupPickup &&
                            summary.PricingPostalCodeGroupClients[0]
                                .PricingPostalCodeGroupDelivery
                        "
                    >
                        <div
                            class="flex flex-col mt-3 items-start justify-end gap-[5px]"
                        >
                            <div class="mt-2 font-semibold">
                                {{
                                    $t(
                                        'postalcode_groups_tc',
                                        summary.PricingPostalCodeGroupClients
                                            .length === 1
                                            ? 1
                                            : 2,
                                    )
                                }}
                            </div>
                            <div class="grid grid-cols-2 gap-2">
                                <div
                                    v-for="(
                                        group, index
                                    ) in summary.PricingPostalCodeGroupClients"
                                    :key="index"
                                >
                                    <div
                                        class="col-span-1 text-sm border-2 rounded p-2 w-full h-full overflow-y-auto"
                                    >
                                        {{
                                            `${group.DateBegin} ⋅ ${group.PricingPostalCodeGroupPickup.Name} -> ${group.PricingPostalCodeGroupDelivery.Name} ⋅ ${group.Value} €`
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="italic mt-3 flex justify-center items-center theme-color"
                        >
                            {{ $t('no_group_selected') }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'PricingSummary',
    props: {
        summary: {
            type: Object,
            required: true,
        },
    },
}
</script>
