<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'postal_groups_list'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="zipGroupsList"
                :items="groups"
                :columns="columns"
                :storeToSort="'groups'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-1/3'"
            :headerText="
                isUpdate ? 'update_postal_code_group' : 'add_postal_code_group'
            "
            @sendFromModal="addGroup()"
            @closeModal="openModal(null)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center italic my-4">
                        {{ $t('group_modal_info') }}
                    </div>
                    <!-- <div
                                class="grid grid-cols-6 gap-2 items-center my-2"
                            > -->
                    <div class="justify-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 mb-2 pb-1 mr-2"
                            >{{ $t('name') + ' *' }}</label
                        >
                        <input
                            :id="`groupName`"
                            type="text"
                            v-model="groupForm.Name"
                            class="border rounded px-3 py-2 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.groupForm.Name.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>

                    <div class="justify-center my-2">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mb-2 mr-2"
                            >{{ $t('type') + ' *' }}</label
                        >
                        <textarea
                            v-model="postalCodes"
                            class="border rounded px-3 py-2 w-full"
                        />

                        <div
                            class="text-xs error-msg flex items-center justify-start"
                            v-if="invalidPostalCodes.length > 0"
                        >
                            {{ $t('bad_zip_format') + ' :' }}
                            <span
                                v-for="(code, index) in invalidPostalCodes"
                                :key="index"
                                class="mx-1"
                                >{{ code.PostalCode }}</span
                            >
                        </div>
                    </div>
                </div>
            </template></modal
        >

        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.title" class="mr-1">
                                    {{ `${toConfirm.title} ` }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { usePricingStore } from '@/stores/pricingStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapStores, mapWritableState } from 'pinia'

const zipControl = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'groupsList',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            invalidPostalCodes: [],
            refresh: 0,
            postalCodes: '',
            searchQuery: '',
            isUpdate: false,
            show: false,
            entityTypeToDelete: 'postalcode_groups_tc',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            groupsInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            newFromParam: false,
            groupForm: {
                Id: '',
                Title: '',
                PricingPostalCodes: [],
            },
        }
    },
    watch: {
        groups() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    validations() {
        return {
            groupForm: {
                Name: { required },
                // PricingPostalCodes: {
                //     minLength: minLength(1),
                //     $each: helpers.forEach({
                //         PostalCode: {
                //             isZipCode: helpers.withMessage(
                //                 this.$t('bad_zip_format'),
                //                 isZipCode,
                //             ),
                //         },
                //     }),
                // },
            },
        }
    },
    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredGroups')
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.groupsActionAccess.update,
                ),
            },

            {
                name: 'postal_codes',
                filtrable: false,
                // dbField: 'Pricing_PostalCode.PostalCode',
                // type: 'search',
                sortable: false,
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.groupsInterval)
    },
    computed: {
        groupsActionAccess() {
            return actionsAccess.pricings.groups
        },
        ...mapWritableState(usePricingStore, {
            groups: 'groups',
            count: 'groupsCount',
            fetchError: 'fetchError',
            totalPages: 'groupsTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.groupsActionAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            // TODO add this action when the dock is created + ADD AUTH

            if (checkAccess(this.userStore, this.groupsActionAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.groupsActionAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteMany',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(usePricingStore, [
            'searchGroups',
            'createGroup',
            'updateGroup',
            'deleteGroup',
            'deleteManyGroups',
        ]),
        upFilter(event) {
            clearInterval(this.groupsInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.groupsActionAccess.create)
        },
        openModal(id = null) {
            clearInterval(this.groupsInterval)
            this.isUpdate = false

            if (id !== null) {
                const groupToUpdate = this.groups.find((p) => p.id === id)

                this.groupForm = {
                    Id: groupToUpdate.id,
                    Name: groupToUpdate.name,
                    PricingPostalCodes: groupToUpdate.postal_codes,
                }
                this.postalCodes = groupToUpdate.postal_codes

                this.isUpdate = true
            } else {
                this.groupForm = {
                    Id: '',
                    Title: '',
                    PricingPostalCodes: [],
                }
                this.postalCodes = ''
            }
            this.show = !this.show
        },
        async addGroup() {
            this.v$.groupForm.$reset()

            this.groupForm.PricingPostalCodes = []
            this.groupForm.PricingPostalCodes = structuredClone(
                this.postalCodes.split(','),
            )
            this.groupForm.PricingPostalCodes.forEach((code, index) => {
                this.groupForm.PricingPostalCodes[index] = {
                    PostalCode: code.trim(),
                }
            })
            this.invalidPostalCodes = []
            this.invalidPostalCodes = this.groupForm.PricingPostalCodes.filter(
                (code) => !code.PostalCode.match(zipControl),
            )
            console.log(this.invalidPostalCodes)
            // vérifier si les code postaux matchent la regex isZipCode
            // TODO vérifier les reset des forms dans harbor et pricing
            // TODO cela ne fonctionne pas, le validator dans validations() semble fonctionner mais comment afficher l'erreur ?
            if (this.invalidPostalCodes.length > 0) {
                this.$toast.error(this.$t('bad_zip_format'))
                return
            }

            const isValid = await this.v$.groupForm.$validate()
            if (isValid === true) {
                if (this.isUpdate) {
                    this.updateGroup(this.groupForm)
                        .then((res) => {
                            this.$toast.success(this.$t('update_ok'))
                            this.setResults()
                            this.openModal(null)
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('update_ko'))
                        })
                } else {
                    this.createGroup(this.groupForm)
                        .then(() => {
                            this.$toast.success(this.$t('create_ok'))
                            this.openModal(null)
                            this.setResults()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('create_ko'))
                        })
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.groupsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.entityTypeToDelete = 'postalcode_groups_tc'

            const group = this.groups.find((h) => h.id === id)
            this.elementsToConfirm.push(group)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteGroup(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.groupsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'postalcode_groups_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const group = this.groups.find((h) => h.id === id)
                this.elementsToConfirm.push(group)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyGroups(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.groupsInterval)
            this.$cookies.remove('filteredGroups')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.groupsInterval)
            this.$cookies.remove('filteredGroups')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchGroups(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredGroups', search)
            this.groupsInterval = setInterval(() => {
                this.searchGroups(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.groupsInterval)
            this.$cookies.remove('filteredGroups')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
