<template>
    <div class="mr-20 bg-white darky">
        <component
            :is="componentToDisplay"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import { mapStores } from 'pinia'
import Harbors from '@/views/harbors/List.vue'
import Docks from '@/views/harbors/DocksList.vue'
import Defenses from '@/views/harbors/DefensesList.vue'
import Inspections from '@/views/harbors/InspectionsList.vue'

import { useHarborStore } from '@/stores/harborStore'

export default {
    name: 'HarborsWrapper',
    components: {
        Harbors,
        Docks,
        Defenses,
        Inspections,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name,
            ),
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filteredHarbors')
        this.$cookies.remove('filteredDocks')
        this.$cookies.remove('filteredDefenses')
        this.$cookies.remove('filteredInspections')
        }
    },
    mounted() {
        // if (this.$route.name === 'update_area') {
        //     this.toUpdate(this.$route.params.id)
        // } else {
        this.changeContent(this.$route.name)
        // }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filteredHarbors')
                this.$cookies.remove('filteredDocks')
                this.$cookies.remove('filteredDefenses')
                this.$cookies.remove('filteredInspections')
            }

            // if (this.sideBarNav === 'update_area') {
            //     this.toUpdate(this.$route.params.id)
            // } else {
            this.changeContent(this.$route.name)
            // }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapStores(useHarborStore),
    },
    methods: {
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
