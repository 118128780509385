<template>
    <div class="mr-20 bg-white darky">
       
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @toUpdate="toUpdate($event)"
        />
    </div>
</template>
<script>
import { mapActions, mapWritableState } from 'pinia'
import NavTabs from '@/components/elements/NavTabs.vue'
import Users from '@/views/users/List.vue'
import RolesList from '@/views/users/RolesList.vue'
import UniversesList from '@/views/users/UniversesList.vue'

import NewUser from '@/views/users/CreateOrUpdate.vue'
import { useAllUsersStore } from '@/stores/allUsersStore'

export default {
    name: 'UsersWrapper',
    components: {
        Users,
        NavTabs,
        NewUser,
        RolesList,
        UniversesList,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [['list-ul', 'users']],
        }
    },
    created() {
        if (this.$cookies.isKey('fromDashboard') === false) {
        this.$cookies.remove('filtered')
        this.$cookies.remove('filteredRoles')
        this.$cookies.get('filteredUniverses')
        }
    },
    mounted() {
        if (this.$route.name === 'update_user') {
            this.toUpdate(this.$route.params.id)
        } else if (this.$route.name === 'new_user') {
            this.toCreate()
        } else {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        this.$cookies.remove('fromDashboard')
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
        this.$cookies.remove('filteredRoles')
        this.$cookies.get('filteredUniverses')

            }

            if (this.sideBarNav === 'update_user') {
                this.toUpdate(this.$route.params.id)
            } else if (this.sideBarNav === 'new_user') {
                this.toCreate()
            } else {
                this.changeContent(this.$route.name)
            }
            this.$emit('removeSidebarNav')
        }
    },
    unmounted() {
        this.$cookies.remove('fromDashboard')
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapWritableState(useAllUsersStore, {
            currentUserInEdition: 'currentUserInEdition',
        }),
    },
    methods: {
        ...mapActions(useAllUsersStore, ['fetchOne']),
        toCreate() {
            this.$emit('removeSidebarNav')

            this.currentUserInEdition = null
            this.componentToDisplay = this.humanize('new_user')
            this.$router.push({ name: 'new_user' })
        },
        toUpdate(id) {
            this.$emit('removeSidebarNav')

            this.currentUserInEdition = null
            this.fetchOne(id).then(() => {
                this.$router.push({ name: 'update_user', params: { id } })
                this.componentToDisplay = this.humanize('new_user')
            })
        },
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
